/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "number": {
    "name": "Number",
    "id": "number"
  },
  "text": {
    "name": "Text",
    "id": "text"
  },
  "textWithTailing": {
    "name": "Text With Tailing",
    "id": "textWithTailing"
  },
  "graphic": {
    "name": "Graphic",
    "id": "graphic"
  }
}

const sheetArray = [
  sheet['number'],
  sheet['text'],
  sheet['textWithTailing'],
  sheet['graphic'],
]

export default sheet
export { sheetArray }
