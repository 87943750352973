import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'

import {
  Text,
  Select,
  Conditional,
  Repeater,
  FileUpload,
  createControlTree,
  unspecifiedValue,
} from '~p/client/control-tree'
import { mapValuesWithKey } from '~p/client/utils'

import SPORT_PREFIX from '../common/sportPrefix'
import sheets from '../common/sheets'

const graphicTexturesSolid = fp.filter({ type: 'solidFill' })(
  sheets.graphicTextures,
)
const toggleOptions = [
  { id: 'on', name: 'On' },
  { id: 'off', name: 'Off' },
]

function getDecoBaseNodes(row, decoId) {
  return {
    position: Select({
      dependencies: [
        'sheet:decoPlacements',
        'garment',
        'placementMap',
        'design.graphicDesign',
      ],
      isAvailable: (_, garment, __, graphicDesign) =>
        graphicDesign.id === 'fullCustom' ?
          false
        : row.subsets[garment.limitations.decoSubsetId],
      isRequired: false,
      options(decoPlacements) {
        const placementIds = row.limitations.decoPlacementIds
        return fp.filter((x) => fp.includes(x.id, placementIds))(decoPlacements)
      },
      defaultValue: row.defaults.enabled ? row.defaults.position : null,
      value: (_, __, placementMap) => (value) => {
        const decoPlacement = placementMap[value]
        return decoPlacement === decoId || decoPlacement === null ? value : null
      },
      onChange: (_, __, placementMap) => (value) => {
        let nextPlacementMap = placementMap

        nextPlacementMap = mapValuesWithKey((placementDeco, placement) => {
          if (placementDeco === decoId && placement !== value) {
            return null
          }

          if (placement === value) {
            return decoId
          }

          return placementDeco
        })(nextPlacementMap)

        return {
          placementMap: nextPlacementMap,
          [`decos.${decoId}.position`]: value,
        }
      },
      label: 'Position',
      changeLabel: `${row.name} Position`,
    }),

    size: Select({
      dependencies: [`decos.${decoId}.position`, 'sheet:decoSizes'],
      isAvailable: (position) => position && row.limitations.decoSizeIds.length,
      options(_, sizes) {
        return fp.filter(({ id }) =>
          fp.includes(id, row.limitations.decoSizeIds),
        )(sizes)
      },
      defaultValue: row.defaults.size,
      label: 'Size',
      changeLabel: `${row.name} Size`,
    }),
  }
}

function getDecoFillNodes(row, decoId) {
  return {
    graphicTexture: Select({
      dependencies: ['sheet:graphicTextures'],
      options: (graphicTextures) => graphicTextures,
      label: 'Graphic Texture',
      changeLabel: `${row.name} Graphic Texture`,
    }),

    colorCount: Text({
      isPrivate: true,
      dependencies: [`decos.${decoId}.content.fill.graphicTexture`],
      value: (graphicTexture) => () =>
        graphicTexture ? graphicTexture.asset.colorCount : 1,
    }),

    color1: Select({
      dependencies: ['sheet:colors', `decos.${decoId}.content.fill.colorCount`],
      options: (colors) => colors,
      isAvailable: (colors, colorCount) => colorCount >= 1,
      defaultValue: 'white',
      label: (colors, colorCount) => (colorCount > 1 ? 'Color 1' : 'Color'),
      changeLabel: (colors, colorCount) =>
        colorCount > 1 ? `${row.name} Color 1` : `${row.name} Color`,
    }),

    color2: Select({
      dependencies: ['sheet:colors', `decos.${decoId}.content.fill.colorCount`],
      options: (colors) => colors,
      isAvailable: (colors, colorCount) => colorCount >= 2,
      defaultValue: 'black',
      label: 'Color 2',
      changeLabel: `${row.name} Color 2`,
    }),

    color3: Select({
      dependencies: ['sheet:colors', `decos.${decoId}.content.fill.colorCount`],
      options: (colors) => colors,
      isAvailable: (colors, colorCount) => colorCount >= 3,
      defaultValue: 'darkGray',
      label: 'Color 3',
      changeLabel: `${row.name} Color 3`,
    }),
  }
}

function getDecoTextNodes(row, decoId) {
  return {
    text: Text({
      defaultValue: row.defaults.text,
      label: 'Text',
      changeLabel: `${row.name} Text`,
    }),

    font: Select({
      dependencies: ['sheet:letteringFonts'],
      options: (fonts) =>
        fp.filter(`subsets.${row.limitations.letteringFontSubsetId}`)(fonts),
      defaultValue: row.defaults.font,
      label: 'Font',
      changeLabel: `${row.name} Font`,
    }),

    layout: Select({
      dependencies: ['sheet:letteringLayouts'],
      options: (layouts) => layouts,
      defaultValue: 'straight',
      label: 'Layout',
      changeLabel: `${row.name} Layout`,
    }),

    outlineColor1: Select({
      dependencies: ['sheet:colors'],
      isRequired: false,
      options: (colors) => colors,
      defaultValue: 'black',
      label: 'Outline Color 1',
      changeLabel: `${row.name} Outline Color 1`,
    }),

    outlineColor2: Select({
      dependencies: ['sheet:colors', `decos.${decoId}.content.outlineColor1`],
      isAvailable: (_, color1) => color1,
      isRequired: false,
      options: (colors) => colors,
      defaultValue: null,
      label: 'Outline Color 2',
      changeLabel: `${row.name} Outline Color 2`,
    }),

    fill: getDecoFillNodes(row, decoId),
  }
}

function getDecoTextWithTailingNodes(row, decoId) {
  return {
    ...getDecoTextNodes(row, decoId),

    tailingAvailable: Text({
      isPrivate: true,
      value: true,
    }),

    tailEnabled: Select({
      defaultValue: 'off',
      options: toggleOptions,
      label: 'Tail Enabled',
      changeLabel: `${row.name} Tail Enabled`,
    }),

    tailShape: Select({
      dependencies: [
        'sheet:letteringTails',
        `decos.${decoId}.content.font`,
        `decos.${decoId}.content.tailEnabled`,
      ],
      isAvailable: (_, font, tailEnabled) =>
        !!(tailEnabled.id === 'on' && font),
      options: (tails, font) =>
        fp.filter(`subsets.${font.limitations.letteringTailSubsetId}`)(tails),
      label: 'Tail Shape',
      changeLabel: `${row.name} Tail Shape`,
    }),

    tailText: Text({
      dependencies: [`decos.${decoId}.content.tailEnabled`],
      isAvailable: (tailEnabled) => tailEnabled.id === 'on',
      label: 'Tail Text',
      changeLabel: `${row.name} Tail Text`,
      defaultValue: 'Tailtext',
    }),

    tailTextFont: Select({
      dependencies: [
        'sheet:letteringFonts',
        `decos.${decoId}.content.tailEnabled`,
      ],
      isAvailable: (_, tailEnabled) => tailEnabled.id === 'on',
      options: (fonts) =>
        fp.filter(`subsets.${row.limitations.letteringFontSubsetId}`)(fonts),
      label: 'Tail Text Font',
      changeLabel: `${row.name} Tail Text Font`,
    }),

    tailTextColor: Select({
      dependencies: ['sheet:colors', `decos.${decoId}.content.tailEnabled`],
      isAvailable: (_, tailEnabled) => tailEnabled.id === 'on',
      options: (colors) => colors,
      label: 'Tail Text Color',
      changeLabel: `${row.name} Tail Text Color`,
    }),
  }
}

function getDecoNumberNodes(row, decoId) {
  const numberTextNode = Text({
    dependencies: ['playerNumber'],
    defaultValue: row.defaults.text,
    label: 'Text',
    changeLabel: `${row.name} Text`,
    value(playerNumber) {
      return () => playerNumber
    },
    onChange: () => (value) => ({ playerNumber: value }),
  })

  const nodes = fp.omit(['layout'], getDecoTextNodes(row, decoId))
  return fp.set('text', numberTextNode)(nodes)
}

function getDecoGraphicNodes(row) {
  return {
    previewImage: FileUpload({
      label: 'Preview Image',
      changeLabel: `${row.name} Preview Image`,
      defaultValue: {
        id: 'default',
        filename: 'yourTeamLogo.svg',
      },
    }),
    factoryFile: FileUpload({
      label: 'Hi-Res File',
      changeLabel: `${row.name} Hi-Res File`,
    }),
  }
}

function getFillColorNode(id, colorNr, defaultColorId) {
  const isAvailable = (colorCount) => colorCount >= colorNr
  const label = (colorCount) => (colorCount > 1 ? `Color ${colorNr}` : 'Color')
  const defaultValue = (_, colorOptions, restrictedColorOptions) => {
    if (fp.find({ id: defaultColorId })(restrictedColorOptions)) {
      return unspecifiedValue
    }
    if (fp.find({ id: defaultColorId })(colorOptions)) {
      return defaultColorId
    }
    return unspecifiedValue
  }

  if (id === 'mizunoLogo') {
    return Select({
      dependencies: [
        `fillAreas.${id}.colorCount`,
        `fillAreas.${id}.colorOptions`,
        `fillAreas.${id}.restrictedColorOptions`,
      ],
      options: (_, colorOptions) => colorOptions,
      visibleOptions: (_, colorOptions, restrictedColorOptions) =>
        fp.without(restrictedColorOptions)(colorOptions),
      isAvailable,
      label,
      defaultValue,
    })
  }

  return Select({
    dependencies: [
      `fillAreas.${id}.colorCount`,
      `fillAreas.${id}.colorOptions`,
    ],
    options: (_, colorOptions) => colorOptions,
    isAvailable,
    label,
    defaultValue,
  })
}

function getFillAreaNodes(fillArea) {
  const id = fillArea.id
  const defaultColorIds = fillArea.defaults.colors[SPORT_PREFIX]
  const colorSubsetId = fillArea.limitations.colorSubsetId

  return {
    isEnabled: Text({
      isPrivate: true,
      dependencies: ['garment', 'design.graphicDesign'],
      isAvailable: (garment, graphicDesign) => {
        if (fillArea.limitations.ifSolidPanelDesign) {
          return graphicDesign.id === 'solidPanel'
        }

        return (
          fillArea.subsets[garment.limitations.fillAreaSubsetId] &&
          garment.limitations.colorSubsetId &&
          graphicDesign.id !== 'fullCustom'
        )
      },
      value: true,
      label: 'Enabled',
    }),

    graphicTexture: Select({
      isRequired: fillArea.isRequired,
      dependencies: [
        `fillAreas.${id}.isEnabled`,
        'sheet:graphicTextures',
        'garment',
        'design.graphicDesign',
      ],
      isAvailable: (isEnabled) => isEnabled,
      options: (_, graphicTextures, garment, graphicDesign) => {
        const predicate =
          garment.limitations.graphicTextureIsAvailable[id] &&
          graphicDesign.limitations.graphicTextureIsAvailable
        return predicate ? graphicTextures : graphicTexturesSolid
      },
      label: 'Graphic Texture',
    }),

    colorCount: Text({
      isPrivate: true,
      dependencies: [
        `fillAreas.${id}.isEnabled`,
        `fillAreas.${id}.graphicTexture`,
      ],
      isAvailable: (isEnabled) => isEnabled,
      value: (_, graphicTexture) => () =>
        graphicTexture ? graphicTexture.asset.colorCount : 0,
    }),

    colorOptions: Text({
      isPrivate: true,
      dependencies: [`fillAreas.${id}.isEnabled`, 'sheet:colors', 'garment'],
      isAvailable: (isEnabled) => isEnabled,
      value: (_, colors, garment) => () =>
        fp.filter(
          `subsets.${colorSubsetId || garment.limitations.colorSubsetId}`,
        )(colors),
    }),

    restrictedColorOptions:
      id === 'mizunoLogo' ?
        Text({
          isPrivate: true,
          dependencies: [
            `fillAreas.${id}.isEnabled`,
            'garmentType',
            `fillAreas.body.color1`,
            `fillAreas.body.color2`,
            `fillAreas.body.color3`,
            `fillAreas.loop.color1`,
            `fillAreas.loop.color2`,
            `fillAreas.loop.color3`,
          ],
          isAvailable: (isEnabled) => isEnabled,
          value:
            (isEnabled, garmentType, ...dependencyColors) =>
            () => {
              const [
                bodyColor1,
                bodyColor2,
                bodyColor3,
                loopColor1,
                loopColor2,
                loopColor3,
              ] = dependencyColors

              if (garmentType.id === 'jersey') {
                return fp.compact([bodyColor1, bodyColor2, bodyColor3])
              }

              if (garmentType.id === 'pant') {
                return fp.compact([loopColor1, loopColor2, loopColor3])
              }

              return null
            },
        })
      : undefined,

    color1: getFillColorNode(id, 1, defaultColorIds[0]),
    color2: getFillColorNode(id, 2, defaultColorIds[1]),
    color3: getFillColorNode(id, 3, defaultColorIds[2]),
  }
}

function getDecoNodes(row) {
  const decoId = row.id
  const type = row.limitations.contentTypeId
  const contentNodes = {
    text: getDecoTextNodes,
    textWithTailing: getDecoTextWithTailingNodes,
    number: getDecoNumberNodes,
    graphic: getDecoGraphicNodes,
  }[type](row, decoId)

  return {
    ...getDecoBaseNodes(row, decoId),

    content: Conditional({
      dependencies: [`decos.${decoId}.position`],
      lookup: (position) => (position ? 'on' : 'off'),
      variants: {
        on: contentNodes,
      },
    }),
  }
}

const controls = {
  garment: Select({
    options: sheets.garments,
    visibleOptions: _.filter(
      sheets.garments,
      (garment) => garment.garmentTypeId !== 'pant',
    ),
    label: 'Garment',
  }),

  garmentType: Select({
    isPrivate: true,
    dependencies: ['sheet:garmentTypes', 'garment'],
    options: (garmentTypes, garment) => [garmentTypes[garment.garmentTypeId]],
    label: 'Garment Type',
  }),

  fabric: Select({
    dependencies: ['sheet:fabrics', 'garment'],
    options: (fabrics, garment) =>
      fp.filter(
        (row) => row.subsets[garment.limitations.fabricSubsetId],
        fabrics,
      ),
    label: 'Fabric',
  }),

  design: {
    designType: Text({
      dependencies: ['design.graphicDesign'],
      isPrivate: true,
      value: (graphicDesign) => () => graphicDesign.type,
      label: 'Design Type',
    }),

    graphicDesign: Select({
      dependencies: ['sheet:graphicDesigns', 'garment'],
      options: (graphicDesigns, garment) =>
        fp.filter(
          (row) => row.subsets[garment.limitations.designSubsetId],
          graphicDesigns,
        ),
      garment: (_, garment) => garment,
      label: 'Graphic Design',
    }),

    accent1: Select({
      dependencies: ['sheet:colors', 'design.graphicDesign'],
      options: (options) => options,
      label: 'Accent Color 1',
      defaultValue: 'white',
      isAvailable(colors, design) {
        return design && design.asset.accentCount >= 1
      },
    }),

    accent2: Select({
      dependencies: ['sheet:colors', 'design.graphicDesign'],
      options: (options) => options,
      label: 'Accent Color 2',
      defaultValue: 'black',
      isAvailable(colors, design) {
        return design && design.asset.accentCount >= 2
      },
    }),

    accent3: Select({
      dependencies: ['sheet:colors', 'design.graphicDesign'],
      options: (options) => options,
      label: 'Accent Color 3',
      defaultValue: 'royal',
      isAvailable(colors, design) {
        return design && design.asset.accentCount >= 3
      },
    }),
  },

  fillAreas: fp.mapValues(getFillAreaNodes, sheets.fillAreas),

  playerNumber: Text({
    isPrivate: true,
    defaultValue: sheets.decos.frontNumber.defaults.text,
    maxLength: 2,
    pattern: /^[0-9]+$/,
    inputType: 'tel',
    subline: 'Maximum 2 characters.',
  }),

  placementMap: Text({
    dependencies: ['sheet:decoPlacements', 'sheet:decos', 'garment'],
    defaultValue(decoPlacements, decos, garment) {
      const enabledDecos = fp.pipe(
        fp.filter((deco) => deco.subsets[garment.limitations.decoSubsetId]),
        fp.filter((deco) => deco.defaults.enabled),
        fp.keyBy('defaults.position'),
        fp.mapValues('id'),
      )(decos)

      return fp.pipe(
        fp.keyBy('id'),
        fp.mapValues(({ id }) => enabledDecos[id] || null),
      )(decoPlacements)
    },
  }),

  decos: fp.mapValues(getDecoNodes, sheets.decos),

  piping: {
    positions: Select({
      dependencies: ['sheet:pipings', 'garment', 'design.graphicDesign'],
      multiple: true,
      isRequired: false,
      isAvailable: (_, garment, graphicDesign) =>
        graphicDesign.id !== 'fullCustom' &&
        garment.limitations.pipingSubsetId != null,
      options: (pipings, garment) =>
        fp.filter(`subsets.${garment.limitations.pipingSubsetId}`)(pipings),
      label: 'Piping Positions',
    }),

    content: Conditional({
      dependencies: ['piping.positions'],
      lookup: (positions) => (positions && positions.length > 0 ? 'on' : 'off'),
      variants: {
        on: {
          color1: Select({
            dependencies: ['sheet:colors'],
            options: (colors) => colors,
            label: 'Piping Color 1',
          }),

          color2: Select({
            dependencies: ['sheet:colors'],
            isRequired: false,
            options: (colors) => colors,
            label: 'Piping Color 2',
          }),

          color3: Select({
            dependencies: ['sheet:colors', 'piping.content.color2'],
            isAvailable: (_, color2) => color2,
            isRequired: false,
            options: (colors) => colors,
            label: 'Piping Color 3',
          }),
        },
      },
    }),
  },

  details: {
    specialInstructions: {
      text: Text({
        maxLength: 120,
        label: 'Special Instructions',
        subline: 'Maximum 120 characters.',
      }),
    },

    recipeName: {
      text: Text({
        maxLength: 30,
        label: 'Design Name',
        subline: 'Maximum 30 characters.',
      }),
    },

    roster: Repeater({
      controls: {
        size: Select({
          dependencies: [
            'sheet:rosterSizes',
            'garment',
            'fillAreas.body.color1',
          ],
          stopOnError: true,
          optionGroups: (sizes, garment, bodyColor) => {
            let roasterSizeSubsetId = garment.limitations.roasterSizeSubsetId

            // https://app.asana.com/0/156328034693579/191298399791008
            // black full length semi custom baseball pant only comes in YOUTH sizes
            if (
              garment.id === 'fullLength-semiCustom' &&
              bodyColor &&
              bodyColor.id === 'black'
            ) {
              roasterSizeSubsetId += 'Youth'
            }

            // https://app.asana.com/0/156328034693579/192326657860324
            // royal semi custom softball pant only comes in ADULT sizes
            if (
              bodyColor &&
              bodyColor.id === 'royal' &&
              garment.sportId === 'softball' &&
              garment.garmentTypeId === 'pant' &&
              garment.variantId === 'semiCustom'
            ) {
              roasterSizeSubsetId += 'Adult'
            }

            return fp.pipe(
              fp.filter(`subsets.${roasterSizeSubsetId}`),
              fp.groupBy('group'),
            )(sizes)
          },
          label: 'Roster Size',
        }),

        quantity: Text({
          maxLength: 3,
          defaultValue: '1',
          label: 'Quantity',
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        number: Text({
          maxLength: 2,
          label: 'Nr.',
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        name: Text({
          maxLength: 15,
          label: 'Jersey Name',
        }),
      },
    }),
  },
}

const controlTree = createControlTree(controls)

export default controlTree
