import { connect } from 'react-redux'
import { setPreviewGenerator } from '~p/client/common/actions'

import Render3d from '../components/Render3d'
import * as actionCreators from '../actions'

const mapStateToProps = (state, { controlTree, get3dConfig, viewAngles }) => {
  const expandedRecipe = controlTree.getExpandedRecipe(state)
  const scene = get3dConfig(expandedRecipe, state.render3d)

  return {
    scene,
    viewAngles,
    activeView: state.render3d.activeView,
    interactivityMode: state.render3d.interactivityMode,
    isPreviewMinimized: state.isPreviewMinimized,
    zoomMode: state.render3d.zoomMode,
    isLoading: state.render3d.isLoading,
  }
}

const mapDispatchToProps = {
  ...actionCreators,
  setPreviewGenerator,
}

export default connect(mapStateToProps, mapDispatchToProps)(Render3d)
