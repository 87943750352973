/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "straight": {
    "name": "Straight",
    "id": "straight"
  },
  "bridge": {
    "name": "Bridge",
    "id": "bridge"
  },
  "bridgeBookends": {
    "name": "Bridge Bookends",
    "id": "bridgeBookends"
  },
  "reversedBookends": {
    "name": "Reversed Bookends",
    "id": "reversedBookends"
  },
  "arched": {
    "name": "Arched",
    "id": "arched"
  },
  "verticalArch": {
    "name": "Vertical Arch",
    "id": "verticalArch"
  },
  "archedBookends": {
    "name": "Arched Bookends",
    "id": "archedBookends"
  },
  "sweep": {
    "name": "Sweep",
    "id": "sweep"
  }
}

const sheetArray = [
  sheet['straight'],
  sheet['bridge'],
  sheet['bridgeBookends'],
  sheet['reversedBookends'],
  sheet['arched'],
  sheet['verticalArch'],
  sheet['archedBookends'],
  sheet['sweep'],
]

export default sheet
export { sheetArray }
