/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "solid": {
    "name": "Solid",
    "id": "solid",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": true,
      "volleyballFemale": true,
      "volleyballMale": true,
      "volleyballReplica": true,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": true,
      "fullSoftballPant": true
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "spider": {
    "name": "Spider",
    "id": "spider",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "pinstripe": {
    "name": "Pinstripe",
    "id": "pinstripe",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "bigStripe": {
    "name": "Big Stripe",
    "id": "bigStripe",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "checks": {
    "name": "Checks",
    "id": "checks",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "oldschool": {
    "name": "Oldschool",
    "id": "oldschool",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "retro": {
    "name": "Retro",
    "id": "retro",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "throwback": {
    "name": "Throwback",
    "id": "throwback",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "chevron": {
    "name": "Chevron",
    "id": "chevron",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "ombre": {
    "name": "Ombre",
    "id": "ombre",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "set": {
    "name": "Set",
    "id": "set",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "wings": {
    "name": "Wings",
    "id": "wings",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "splatter": {
    "name": "Splatter",
    "id": "splatter",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "tyeDye": {
    "name": "Tye Dye",
    "id": "tyeDye",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "tiger": {
    "name": "Tiger",
    "id": "tiger",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "phenom": {
    "name": "Phenom",
    "id": "phenom",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "swirl": {
    "name": "Swirl",
    "id": "swirl",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "guru": {
    "name": "Guru",
    "id": "guru",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "fullCustom": {
    "name": "Full Custom",
    "id": "fullCustom",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "star": {
    "name": "Star",
    "id": "star",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": [
        "bbsb-frontLeft",
        "bbsb-frontRight"
      ]
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "graph": {
    "name": "Graph",
    "id": "graph",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "flash": {
    "name": "Flash",
    "id": "flash",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "swift": {
    "name": "Swift",
    "id": "swift",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "stemThrowback": {
    "name": "Stem Throwback",
    "id": "stemThrowback",
    "type": "regular",
    "subsets": {
      "baseballJersey": true,
      "baseballJerseyWithoutSpider": true,
      "baseballJerseyWithoutSpiderAndChecks": true,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "sail": {
    "name": "Sail",
    "id": "sail",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "kick": {
    "name": "Kick",
    "id": "kick",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "switch": {
    "name": "Switch",
    "id": "switch",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "burst": {
    "name": "Burst",
    "id": "burst",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "wave": {
    "name": "Wave",
    "id": "wave",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "spotlight": {
    "name": "Spotlight",
    "id": "spotlight",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "arc": {
    "name": "Arc",
    "id": "arc",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "lighting": {
    "name": "Lighting",
    "id": "lighting",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "sideWinder": {
    "name": "SideWinder",
    "id": "sideWinder",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "m": {
    "name": "M",
    "id": "m",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "dig": {
    "name": "Dig",
    "id": "dig",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "compound": {
    "name": "Compound",
    "id": "compound",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": true,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "turn": {
    "name": "Turn",
    "id": "turn",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "fade": {
    "name": "Fade",
    "id": "fade",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "hazard": {
    "name": "Hazard",
    "id": "hazard",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "mosaic": {
    "name": "Mosaic",
    "id": "mosaic",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": true,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "prep": {
    "name": "Prep",
    "id": "prep",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "armor": {
    "name": "Armor",
    "id": "armor",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "diamond": {
    "name": "Diamond",
    "id": "diamond",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": true,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "frequency": {
    "name": "Frequency",
    "id": "frequency",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "groove": {
    "name": "Groove",
    "id": "groove",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "key": {
    "name": "Key",
    "id": "key",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "uno": {
    "name": "Uno",
    "id": "uno",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "moto": {
    "name": "Moto",
    "id": "moto",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": true,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "lines": {
    "name": "Lines",
    "id": "lines",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "block": {
    "name": "Block",
    "id": "block",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": true,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "red": {
    "name": "Red 2015",
    "id": "red",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "navy": {
    "name": "Navy 2015",
    "id": "navy",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "white": {
    "name": "White 2015",
    "id": "white",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "red2016": {
    "name": "Red 2016",
    "id": "red2016",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "navy2016": {
    "name": "Navy 2016",
    "id": "navy2016",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "white2016": {
    "name": "White 2016",
    "id": "white2016",
    "type": "regular",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": true,
      "semiBaseballPant": false,
      "semiSoftballPant": false,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": true,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "solidPanel": {
    "name": "Solid Panel",
    "id": "solidPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 0,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "doubleStripe": {
    "name": "Double Stripe Panel",
    "id": "doubleStripe",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "tripleStripe": {
    "name": "Triple Stripe Panel",
    "id": "tripleStripe",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "proStripe": {
    "name": "Pro Stripe Panel",
    "id": "proStripe",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 3,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "checksPanel": {
    "name": "Checks Panel",
    "id": "checksPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": true,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "floralPanel": {
    "name": "Floral Panel",
    "id": "floralPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "argylePanel": {
    "name": "Argyle Panel",
    "id": "argylePanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "diamondPanel": {
    "name": "Diamond Panel",
    "id": "diamondPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "tyeDyePanel": {
    "name": "Tye Dye Panel",
    "id": "tyeDyePanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "zebraPanel": {
    "name": "Zebra Panel",
    "id": "zebraPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": true,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": true,
      "hasGradient": false,
      "accentCount": 1,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "splatterPanel": {
    "name": "Splatter Panel",
    "id": "splatterPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "chevronPanel": {
    "name": "Chevron Panel",
    "id": "chevronPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  },
  "ombrePanel": {
    "name": "Ombre Panel",
    "id": "ombrePanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": true,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": false
    }
  },
  "tigerPanel": {
    "name": "Tiger Panel",
    "id": "tigerPanel",
    "type": "panel",
    "subsets": {
      "baseballJersey": false,
      "baseballJerseyWithoutSpider": false,
      "baseballJerseyWithoutSpiderAndChecks": false,
      "softballJersey": false,
      "volleyballFemale": false,
      "volleyballMale": false,
      "volleyballReplica": false,
      "semiBaseballPant": false,
      "semiSoftballPant": true,
      "fullBaseballPant": false,
      "fullSoftballPant": false
    },
    "asset": {
      "isStaticLayer": false,
      "hasStaticLayers": false,
      "hasGradient": false,
      "accentCount": 2,
      "designSpecificPlacements": []
    },
    "limitations": {
      "graphicTextureIsAvailable": true
    }
  }
}

const sheetArray = [
  sheet['solid'],
  sheet['spider'],
  sheet['pinstripe'],
  sheet['bigStripe'],
  sheet['checks'],
  sheet['oldschool'],
  sheet['retro'],
  sheet['throwback'],
  sheet['chevron'],
  sheet['ombre'],
  sheet['set'],
  sheet['wings'],
  sheet['splatter'],
  sheet['tyeDye'],
  sheet['tiger'],
  sheet['phenom'],
  sheet['swirl'],
  sheet['guru'],
  sheet['fullCustom'],
  sheet['star'],
  sheet['graph'],
  sheet['flash'],
  sheet['swift'],
  sheet['stemThrowback'],
  sheet['sail'],
  sheet['kick'],
  sheet['switch'],
  sheet['burst'],
  sheet['wave'],
  sheet['spotlight'],
  sheet['arc'],
  sheet['lighting'],
  sheet['sideWinder'],
  sheet['m'],
  sheet['dig'],
  sheet['compound'],
  sheet['turn'],
  sheet['fade'],
  sheet['hazard'],
  sheet['mosaic'],
  sheet['prep'],
  sheet['armor'],
  sheet['diamond'],
  sheet['frequency'],
  sheet['groove'],
  sheet['key'],
  sheet['uno'],
  sheet['moto'],
  sheet['lines'],
  sheet['block'],
  sheet['red'],
  sheet['navy'],
  sheet['white'],
  sheet['red2016'],
  sheet['navy2016'],
  sheet['white2016'],
  sheet['solidPanel'],
  sheet['doubleStripe'],
  sheet['tripleStripe'],
  sheet['proStripe'],
  sheet['checksPanel'],
  sheet['floralPanel'],
  sheet['argylePanel'],
  sheet['diamondPanel'],
  sheet['tyeDyePanel'],
  sheet['zebraPanel'],
  sheet['splatterPanel'],
  sheet['chevronPanel'],
  sheet['ombrePanel'],
  sheet['tigerPanel'],
]

export default sheet
export { sheetArray }
