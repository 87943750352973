/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "yS": {
    "name": "YS",
    "id": "yS",
    "group": "Youth",
    "printName": "Youth S",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": true,
      "softballPantsAdult": false,
      "volleyball": false
    }
  },
  "yM": {
    "name": "YM",
    "id": "yM",
    "group": "Youth",
    "printName": "Youth M",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": true,
      "softballPantsAdult": false,
      "volleyball": true
    }
  },
  "yL": {
    "name": "YL",
    "id": "yL",
    "group": "Youth",
    "printName": "Youth L",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": true,
      "softballPantsAdult": false,
      "volleyball": true
    }
  },
  "yXL": {
    "name": "YXL",
    "id": "yXL",
    "group": "Youth",
    "printName": "Youth XL",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": false,
      "softballPantsAdult": false,
      "volleyball": false
    }
  },
  "yXXL": {
    "name": "YXXL",
    "id": "yXXL",
    "group": "Youth",
    "printName": "Youth XXL",
    "subsets": {
      "all": true,
      "jerseys": false,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": false,
      "softballPantsAdult": false,
      "volleyball": false
    }
  },
  "yXXXL": {
    "name": "YXXXL",
    "id": "yXXXL",
    "group": "Youth",
    "printName": "Youth XXXL",
    "subsets": {
      "all": true,
      "jerseys": false,
      "baseballPants": true,
      "baseballPantsAdult": false,
      "baseballPantsYouth": true,
      "softballPants": false,
      "softballPantsAdult": false,
      "volleyball": false
    }
  },
  "XXS": {
    "name": "XXS",
    "id": "XXS",
    "group": "Adult",
    "printName": "XXS",
    "subsets": {
      "all": false,
      "jerseys": false,
      "baseballPants": false,
      "baseballPantsAdult": false,
      "baseballPantsYouth": false,
      "softballPants": false,
      "softballPantsAdult": false,
      "volleyball": true
    }
  },
  "XS": {
    "name": "XS",
    "id": "XS",
    "group": "Adult",
    "printName": "XS",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "S": {
    "name": "S",
    "id": "S",
    "group": "Adult",
    "printName": "S",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "M": {
    "name": "M",
    "id": "M",
    "group": "Adult",
    "printName": "M",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "L": {
    "name": "L",
    "id": "L",
    "group": "Adult",
    "printName": "L",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "XL": {
    "name": "XL",
    "id": "XL",
    "group": "Adult",
    "printName": "XL",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "XXL": {
    "name": "XXL",
    "id": "XXL",
    "group": "Adult",
    "printName": "XXL",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": true,
      "baseballPantsAdult": true,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": true
    }
  },
  "XXXL": {
    "name": "XXXL",
    "id": "XXXL",
    "group": "Adult",
    "printName": "XXXL",
    "subsets": {
      "all": true,
      "jerseys": true,
      "baseballPants": false,
      "baseballPantsAdult": false,
      "baseballPantsYouth": false,
      "softballPants": true,
      "softballPantsAdult": true,
      "volleyball": false
    }
  }
}

const sheetArray = [
  sheet['yS'],
  sheet['yM'],
  sheet['yL'],
  sheet['yXL'],
  sheet['yXXL'],
  sheet['yXXXL'],
  sheet['XXS'],
  sheet['XS'],
  sheet['S'],
  sheet['M'],
  sheet['L'],
  sheet['XL'],
  sheet['XXL'],
  sheet['XXXL'],
]

export default sheet
export { sheetArray }
