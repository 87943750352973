import configureStore from '~p/client/configureStore'

import updater from './updater'
import controlTree from './controlTree'

const store = configureStore({
  updater,
  persist: ['openSections'],
  controlTree,
})

export default store
