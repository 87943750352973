/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "setIn": {
    "name": "Set-In Sleeve",
    "id": "setIn"
  },
  "sleeveless": {
    "name": "Sleeveless",
    "id": "sleeveless"
  },
  "semiCustom": {
    "name": "Semi Custom",
    "id": "semiCustom"
  },
  "fullCustom": {
    "name": "Full Custom",
    "id": "fullCustom"
  },
  "raglan": {
    "name": "Raglan Sleeve",
    "id": "raglan"
  },
  "capSleeve": {
    "name": "Cap Sleeve",
    "id": "capSleeve"
  },
  "shortSleeve": {
    "name": "Short Sleeve",
    "id": "shortSleeve"
  },
  "longSleeve": {
    "name": "Long Sleeve",
    "id": "longSleeve"
  }
}

const sheetArray = [
  sheet['setIn'],
  sheet['sleeveless'],
  sheet['semiCustom'],
  sheet['fullCustom'],
  sheet['raglan'],
  sheet['capSleeve'],
  sheet['shortSleeve'],
  sheet['longSleeve'],
]

export default sheet
export { sheetArray }
