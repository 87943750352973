/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "fullFront": {
    "sportPrefix": "bbsb",
    "id": "fullFront",
    "uniqueId": "bbsb-fullFront",
    "name": "Full Front",
    "asset": {
      "layerId": "fullFront",
      "width": 13,
      "height": 5.4
    },
    "limitations": {
      "overlapingDecoPlacementIds": [
        "leftChest"
      ]
    }
  },
  "leftChest": {
    "sportPrefix": "bbsb",
    "id": "leftChest",
    "uniqueId": "bbsb-leftChest",
    "name": "Left Chest",
    "asset": {
      "layerId": "leftChest",
      "width": 3.5,
      "height": 2.5
    },
    "limitations": {
      "overlapingDecoPlacementIds": [
        "fullFront"
      ]
    }
  },
  "upperBack": {
    "sportPrefix": "bbsb",
    "id": "upperBack",
    "uniqueId": "bbsb-upperBack",
    "name": "Upper Back",
    "asset": {
      "layerId": "upperBack",
      "width": 12,
      "height": 3
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "sleeveLeft": {
    "sportPrefix": "bbsb",
    "id": "sleeveLeft",
    "uniqueId": "bbsb-sleeveLeft",
    "name": "Left Sleeve",
    "asset": {
      "layerId": "sleeveLeft",
      "width": 4,
      "height": 3.5
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "sleeveRight": {
    "sportPrefix": "bbsb",
    "id": "sleeveRight",
    "uniqueId": "bbsb-sleeveRight",
    "name": "Right Sleeve",
    "asset": {
      "layerId": "sleeveRight",
      "width": 4,
      "height": 3.5
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "frontLeft": {
    "sportPrefix": "bbsb",
    "id": "frontLeft",
    "uniqueId": "bbsb-frontLeft",
    "name": "Front Left",
    "asset": {
      "layerId": "frontLeft",
      "width": 4,
      "height": null
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "frontRight": {
    "sportPrefix": "bbsb",
    "id": "frontRight",
    "uniqueId": "bbsb-frontRight",
    "name": "Front Right",
    "asset": {
      "layerId": "frontRight",
      "width": 4,
      "height": null
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "fullBack": {
    "sportPrefix": "bbsb",
    "id": "fullBack",
    "uniqueId": "bbsb-fullBack",
    "name": "Full Back",
    "asset": {
      "layerId": "fullBack",
      "width": null,
      "height": null
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "frontLeftPant": {
    "sportPrefix": "bbsb",
    "id": "frontLeftPant",
    "uniqueId": "bbsb-frontLeftPant",
    "name": "Front Left",
    "asset": {
      "layerId": "frontLeftPant",
      "width": 3,
      "height": 3
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "frontRightPant": {
    "sportPrefix": "bbsb",
    "id": "frontRightPant",
    "uniqueId": "bbsb-frontRightPant",
    "name": "Front Right",
    "asset": {
      "layerId": "frontRightPant",
      "width": 3,
      "height": 3
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  }
}

const sheetArray = [
  sheet['fullFront'],
  sheet['leftChest'],
  sheet['upperBack'],
  sheet['sleeveLeft'],
  sheet['sleeveRight'],
  sheet['frontLeft'],
  sheet['frontRight'],
  sheet['fullBack'],
  sheet['frontLeftPant'],
  sheet['frontRightPant'],
]

export default sheet
export { sheetArray }
