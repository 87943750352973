/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "fullFront": {
    "sportPrefix": "vol",
    "id": "fullFront",
    "uniqueId": "vol-fullFront",
    "name": "Full Front",
    "asset": {
      "layerId": "fullFront",
      "width": null,
      "height": 6
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "fullBack": {
    "sportPrefix": "vol",
    "id": "fullBack",
    "uniqueId": "vol-fullBack",
    "name": "Full Back",
    "asset": {
      "layerId": "fullBack",
      "width": null,
      "height": 8
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "leftChest": {
    "sportPrefix": "vol",
    "id": "leftChest",
    "uniqueId": "vol-leftChest",
    "name": "Left Chest",
    "asset": {
      "layerId": "leftChest",
      "width": 3.5,
      "height": 2.5
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "leftChest_2016": {
    "sportPrefix": "vol",
    "id": "leftChest_2016",
    "uniqueId": "vol-leftChest_2016",
    "name": "Left Chest",
    "asset": {
      "layerId": "leftChest_2016",
      "width": 2.3,
      "height": 3.2
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "upperBack": {
    "sportPrefix": "vol",
    "id": "upperBack",
    "uniqueId": "vol-upperBack",
    "name": "Upper Back",
    "asset": {
      "layerId": "upperBack",
      "width": 5,
      "height": 4
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "lowerBack": {
    "sportPrefix": "vol",
    "id": "lowerBack",
    "uniqueId": "vol-lowerBack",
    "name": "Lower Back",
    "asset": {
      "layerId": "lowerBack",
      "width": 8,
      "height": 6
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "sleeveLeft": {
    "sportPrefix": "vol",
    "id": "sleeveLeft",
    "uniqueId": "vol-sleeveLeft",
    "name": "Left Sleeve",
    "asset": {
      "layerId": "sleeveLeft"
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  },
  "sleeveRight": {
    "sportPrefix": "vol",
    "id": "sleeveRight",
    "uniqueId": "vol-sleeveRight",
    "name": "Right Sleeve",
    "asset": {
      "layerId": "sleeveRight"
    },
    "limitations": {
      "overlapingDecoPlacementIds": []
    }
  }
}

const sheetArray = [
  sheet['fullFront'],
  sheet['fullBack'],
  sheet['leftChest'],
  sheet['leftChest_2016'],
  sheet['upperBack'],
  sheet['lowerBack'],
  sheet['sleeveLeft'],
  sheet['sleeveRight'],
]

export default sheet
export { sheetArray }
