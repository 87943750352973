import fp from 'lodash/fp.js'
import React from 'react'

import { cn, states } from '~p/client/components/utils'

import ViewNavigation from '~mizuno/client/components/atoms/ViewNavigation'
import Button from '~mizuno/client/components/atoms/Button'
import Icon from '~mizuno/client/components/atoms/Icon'
import LoadingIndicator from '~mizuno/client/components/atoms/LoadingIndicator'

import Render3dCanvas from './Render3dCanvas'

const mapWithKey = fp.map.convert({ cap: false })

export default class Render3d extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({ isMinimized: this.props.isPreviewMinimized })
  }

  componentDidMount() {
    this.onViewportChange()

    this.props.setPreviewGenerator(this.props.engine.generatePreviews)

    document.addEventListener('visibilitychange', this.onViewportChange)
    window.addEventListener('resize', this.onViewportChange)
  }

  UNSAFE_componentWillReceiveProps() {
    this.onViewportChange()
  }

  componentDidUpdate(prevProps) {
    this.onMinimizationStateChanged(
      prevProps.isPreviewMinimized,
      this.props.isPreviewMinimized,
    )
  }

  componentWillUnmount() {
    this.props.setPreviewGenerator(undefined)
    window.removeEventListener('resize', this.onViewportChange)
    document.removeEventListener('visibilitychange', this.onViewportChange)
  }

  onMinimizationStateChanged = (wasMinimized, isMinimized) => {
    if (wasMinimized === isMinimized) {
      return
    }

    if (this.minimizationStateChangeTimeout) {
      clearTimeout(this.minimizationStateChangeTimeout)
      this.minimizationStateChangeTimeout = null
    }

    this.minimizationStateChangeTimeout = setTimeout(() => {
      this.minimizationStateChangeTimeout = null
      this.setState({ isMinimized })
    }, 400)
  }

  onViewportChange = () => {
    let { width, height } = this.div ? this.div.getBoundingClientRect() : window
    const { innerWidth: bodyWidth, innerHeight: bodyHeight } = window

    if (bodyWidth < 1000) {
      width = bodyWidth
      height = bodyHeight
    }

    if (width !== this.state.width || height !== this.state.height) {
      this.setState({ width, height })
    }
  }

  minimizationStateChangeTimeout = null

  div

  render() {
    const {
      scene,
      viewAngles,
      activeView,
      interactivityMode,
      setActiveView,
      setFreeInteractivityMode,
      setLoading,
      zoomMode,
      setZoomMode,
      isPreviewMinimized,
      isLoading,
      isHidden,
      engine,
    } = this.props

    const renderButton = (view, key) => {
      const props = {
        classMods: ['view'],
        classStates:
          interactivityMode === 'user' && key === activeView ? ['active'] : [],
        onClick() {
          setActiveView(key)
        },
      }
      return (
        <Button key={key} data-gtm={`camera-${key}`} {...props}>
          {view.label}
        </Button>
      )
    }

    const viewButtons = fp.pipe(
      fp.pickBy((view) => view.exposeForUI && view.label),
      mapWithKey(renderButton),
    )(viewAngles)

    const loadingIndicator = isLoading ? <LoadingIndicator /> : null

    let width = this.state.width ? this.state.width : 1000
    let height = this.state.height ? this.state.height : 1000

    if (this.state.isMinimized && window.innerWidth < 1000) {
      width = Math.max(width, height)
      height = width
    }

    return (
      <div
        className={cn([
          'renderer',
          states([isPreviewMinimized ? 'off' : 'on']),
          states([isHidden ? 'hidden' : 'visible']),
        ])}
      >
        <div
          className="renderer-viewport"
          ref={(node) => {
            this.div = node
          }}
        >
          <Render3dCanvas
            width={width}
            height={height}
            setLoading={setLoading}
            zoomMode={zoomMode}
            setZoomMode={setZoomMode}
            scene={scene}
            viewAngles={viewAngles}
            setFreeInteractivityMode={setFreeInteractivityMode}
            isHidden={isHidden}
            engine={engine}
          />
          {loadingIndicator}
        </div>
        <ViewNavigation withHelp>
          <div className="view-buttons">{viewButtons}</div>
          <div className="renderer-help">
            <Icon name="3d" />
            <span className="help-text is-desktop">
              Click and drag to rotate, mouse wheel to zoom
            </span>
            <span className="help-text is-mobile">
              Swipe to rotate, pinch to zoom
            </span>
          </div>
        </ViewNavigation>
      </div>
    )
  }
}
