/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "forest": {
    "name": "Forest",
    "id": "forest",
    "props": {
      "hex": "#00260F"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "kellyGreen": {
    "name": "Kelly Green",
    "id": "kellyGreen",
    "props": {
      "hex": "#359967"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "limeGreen": {
    "name": "Lime Green",
    "id": "limeGreen",
    "props": {
      "hex": "#a3d663"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "tealBlue": {
    "name": "Teal Blue",
    "id": "tealBlue",
    "props": {
      "hex": "#005E4C"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "divaBlue": {
    "name": "Diva Blue",
    "id": "divaBlue",
    "props": {
      "hex": "#2c8bcc"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "columbiaBlue": {
    "name": "Columbia Blue",
    "id": "columbiaBlue",
    "props": {
      "hex": "#90c6f1"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "royal": {
    "name": "Royal",
    "id": "royal",
    "props": {
      "hex": "#3155a2"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": false,
      "semiShort": false
    }
  },
  "mediumBlue": {
    "name": "Medium Blue",
    "id": "mediumBlue",
    "props": {
      "hex": "#316594"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "navy": {
    "name": "Navy",
    "id": "navy",
    "props": {
      "hex": "#282634"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": false,
      "semiShort": false
    }
  },
  "midnightBlue": {
    "name": "Midnight Blue",
    "id": "midnightBlue",
    "props": {
      "hex": "#2a3652"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "purple": {
    "name": "Purple",
    "id": "purple",
    "props": {
      "hex": "#3a235a"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "electricPurple": {
    "name": "Electric Purple",
    "id": "electricPurple",
    "props": {
      "hex": "#802c69"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "lilac": {
    "name": "Lilac",
    "id": "lilac",
    "props": {
      "hex": "#8969b2"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "violet": {
    "name": "Violet",
    "id": "violet",
    "props": {
      "hex": "#733e85"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "lightViolet": {
    "name": "Light Violet",
    "id": "lightViolet",
    "props": {
      "hex": "#ce95c8"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "lightPink": {
    "name": "Light Pink",
    "id": "lightPink",
    "props": {
      "hex": "#fec4dd"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "pink": {
    "name": "Pink",
    "id": "pink",
    "props": {
      "hex": "#ff8db0"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "firePink": {
    "name": "FirePink",
    "id": "firePink",
    "props": {
      "hex": "#ff5f85"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "shockingPink": {
    "name": "Shocking Pink",
    "id": "shockingPink",
    "props": {
      "hex": "#ff3968"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "red": {
    "name": "Red",
    "id": "red",
    "props": {
      "hex": "#cd2932"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": false,
      "semiShort": false
    }
  },
  "cardinal": {
    "name": "Cardinal",
    "id": "cardinal",
    "props": {
      "hex": "#87202e"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "maroon": {
    "name": "Maroon",
    "id": "maroon",
    "props": {
      "hex": "#55212b"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "espresso": {
    "name": "Espresso",
    "id": "espresso",
    "props": {
      "hex": "#4d2a28"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "mahogany": {
    "name": "Mahogany",
    "id": "mahogany",
    "props": {
      "hex": "#422e2b"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "brown": {
    "name": "Brown",
    "id": "brown",
    "props": {
      "hex": "#61423a"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "orange": {
    "name": "Orange",
    "id": "orange",
    "props": {
      "hex": "#f25632"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "orangeHorizon": {
    "name": "Orange Horizon",
    "id": "orangeHorizon",
    "props": {
      "hex": "#fe6535"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "lightOrange": {
    "name": "Light Orange",
    "id": "lightOrange",
    "props": {
      "hex": "#fe823a"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "athleticGold": {
    "name": "Athletic Gold",
    "id": "athleticGold",
    "props": {
      "hex": "#feb242"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "lemon": {
    "name": "Lemon",
    "id": "lemon",
    "props": {
      "hex": "#f6ea54"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "yellow": {
    "name": "Yellow",
    "id": "yellow",
    "props": {
      "hex": "#fed94f"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "vegasGold": {
    "name": "Vegas Gold",
    "id": "vegasGold",
    "props": {
      "hex": "#cfbe93"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "white": {
    "name": "White",
    "id": "white",
    "props": {
      "hex": "#eeeeee"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": "pantGray",
      "softballPantLogo": "pantGray"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": true,
      "semiShort": true
    }
  },
  "pantGray": {
    "name": "Pant Gray",
    "id": "pantGray",
    "props": {
      "hex": "#b2b1b4"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": "gray",
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": true,
      "semiShort": true
    }
  },
  "lightGray": {
    "name": "Light Gray",
    "id": "lightGray",
    "props": {
      "hex": "#b2b1b4"
    },
    "altColorIds": {
      "extreme": "black",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "gray": {
    "name": "Gray",
    "id": "gray",
    "props": {
      "hex": "#867678"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "slate": {
    "name": "Slate",
    "id": "slate",
    "props": {
      "hex": "#726f71"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "darkGray": {
    "name": "Dark Gray",
    "id": "darkGray",
    "props": {
      "hex": "#5e595d"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": null,
      "softballPantLogo": null
    },
    "subsets": {
      "all": true,
      "semiBelted": false,
      "semiUnbelted": false,
      "semiLong": false,
      "semiShort": false
    }
  },
  "charcoal": {
    "name": "Charcoal",
    "id": "charcoal",
    "props": {
      "hex": "#41363a"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": "darkGray",
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": false,
      "semiLong": true,
      "semiShort": false
    }
  },
  "black": {
    "name": "Black",
    "id": "black",
    "props": {
      "hex": "#000000"
    },
    "altColorIds": {
      "extreme": "white",
      "baseballPantLogo": "charcoal",
      "softballPantLogo": "white"
    },
    "subsets": {
      "all": true,
      "semiBelted": true,
      "semiUnbelted": true,
      "semiLong": true,
      "semiShort": false
    }
  }
}

const sheetArray = [
  sheet['forest'],
  sheet['kellyGreen'],
  sheet['limeGreen'],
  sheet['tealBlue'],
  sheet['divaBlue'],
  sheet['columbiaBlue'],
  sheet['royal'],
  sheet['mediumBlue'],
  sheet['navy'],
  sheet['midnightBlue'],
  sheet['purple'],
  sheet['electricPurple'],
  sheet['lilac'],
  sheet['violet'],
  sheet['lightViolet'],
  sheet['lightPink'],
  sheet['pink'],
  sheet['firePink'],
  sheet['shockingPink'],
  sheet['red'],
  sheet['cardinal'],
  sheet['maroon'],
  sheet['espresso'],
  sheet['mahogany'],
  sheet['brown'],
  sheet['orange'],
  sheet['orangeHorizon'],
  sheet['lightOrange'],
  sheet['athleticGold'],
  sheet['lemon'],
  sheet['yellow'],
  sheet['vegasGold'],
  sheet['white'],
  sheet['pantGray'],
  sheet['lightGray'],
  sheet['gray'],
  sheet['slate'],
  sheet['darkGray'],
  sheet['charcoal'],
  sheet['black'],
]

export default sheet
export { sheetArray }
