import fp from 'lodash/fp.js'
import React from 'react'

export default class Render3dCanvas extends React.Component {
  UNSAFE_componentWillMount() {
    this.engine = this.props.engine
  }

  componentDidMount() {
    this.props.setLoading(true)

    if (this.div) this.div.appendChild(this.engine.canvas)

    this.engine.events.on('user_interaction', this.onUserInteraction)

    this.engine
      .render({
        scene: this.props.scene,
        width: this.props.width,
        height: this.props.height,
      })
      .then(() => {
        this.props.setLoading(false)
      })
  }

  shouldComponentUpdate(nextProps) {
    return (
      fp.some(this.engine.compare(nextProps)) ||
      this.props.isHidden !== nextProps.isHidden ||
      this.props.zoomMode !== nextProps.zoomMode
    )
  }

  componentDidUpdate() {
    const engineProps = {
      scene: this.props.scene,
      width: this.props.width,
      height: this.props.height,
    }
    const cmp = this.engine.compare(engineProps)

    if (this.props.zoomMode === 'auto') {
      this.engine.setCameraRadius(0)
    }

    if (!fp.some(cmp)) {
      return
    }

    if (cmp.assets) {
      this.props.setLoading(true)
    }

    this.engine.render(engineProps).then(() => {
      if (cmp.assets) {
        this.props.setLoading(false)
      }
    })
  }

  componentWillUnmount() {
    while (this.div && this.div.firstChild) {
      this.div.removeChild(this.div.firstChild)
    }
    this.engine.events.off('user_interaction', this.onUserInteraction)
  }

  onUserInteraction = () => {
    if (this.props.scene.cameraState.interactivityMode !== 'free') {
      this.props.setFreeInteractivityMode()
    }

    if (this.props.zoomMode !== 'free') {
      this.props.setZoomMode('free')
    }
  }

  engine
  div

  render() {
    const style = {
      visibility: this.props.isHidden ? 'hidden' : 'visible',
    }

    return (
      <div
        style={style}
        id="render-3d-canvas"
        ref={(node) => {
          this.div = node
        }}
      />
    )
  }
}
