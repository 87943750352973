/* eslint-disable */

// This is generated by a script, see platform/common/importer


import sportId from '../../../../platform/sportId'
import sportPrefix from '../sportPrefix'

import vendors, { sheetArray as vendorsArray } from './data/vendors'
import garmentTypes, { sheetArray as garmentTypesArray } from './data/garmentTypes'
import silhouettes, { sheetArray as silhouettesArray } from './data/silhouettes'
import variants, { sheetArray as variantsArray } from './data/variants'
import pipings, { sheetArray as pipingsArray } from './data/pipings'
import fillAreas, { sheetArray as fillAreasArray } from './data/fillAreas'
import fabrics, { sheetArray as fabricsArray } from './data/fabrics'
import colors, { sheetArray as colorsArray } from './data/colors'
import graphicDesigns, { sheetArray as graphicDesignsArray } from './data/graphicDesigns'
import graphicTextures, { sheetArray as graphicTexturesArray } from './data/graphicTextures'
import decoSizes, { sheetArray as decoSizesArray } from './data/decoSizes'
import decoTypes, { sheetArray as decoTypesArray } from './data/decoTypes'
import letteringFonts, { sheetArray as letteringFontsArray } from './data/letteringFonts'
import letteringTails, { sheetArray as letteringTailsArray } from './data/letteringTails'
import letteringLayouts, { sheetArray as letteringLayoutsArray } from './data/letteringLayouts'
import rosterSizes, { sheetArray as rosterSizesArray } from './data/rosterSizes'


















export const garmentsMap = {}
export const garmentsArrayMap = {}
import garments_baseball, { sheetArray as garments_baseballArray } from './data/garments.baseball'
garmentsMap['baseball'] = garments_baseball
garmentsArrayMap['baseball'] = garments_baseballArray
import garments_softball, { sheetArray as garments_softballArray } from './data/garments.softball'
garmentsMap['softball'] = garments_softball
garmentsArrayMap['softball'] = garments_softballArray
import garments_volleyball, { sheetArray as garments_volleyballArray } from './data/garments.volleyball'
garmentsMap['volleyball'] = garments_volleyball
garmentsArrayMap['volleyball'] = garments_volleyballArray
const garments = garmentsMap[sportId]
const garmentsArray = garmentsArrayMap[sportId]
export const decosMap = {}
export const decosArrayMap = {}
import decos_bbsb, { sheetArray as decos_bbsbArray } from './data/decos.bbsb'
decosMap['bbsb'] = decos_bbsb
decosArrayMap['bbsb'] = decos_bbsbArray
import decos_vol, { sheetArray as decos_volArray } from './data/decos.vol'
decosMap['vol'] = decos_vol
decosArrayMap['vol'] = decos_volArray
const decos = decosMap[sportPrefix]
const decosArray = decosArrayMap[sportPrefix]
export const decoPlacementsMap = {}
export const decoPlacementsArrayMap = {}
import decoPlacements_bbsb, { sheetArray as decoPlacements_bbsbArray } from './data/decoPlacements.bbsb'
decoPlacementsMap['bbsb'] = decoPlacements_bbsb
decoPlacementsArrayMap['bbsb'] = decoPlacements_bbsbArray
import decoPlacements_vol, { sheetArray as decoPlacements_volArray } from './data/decoPlacements.vol'
decoPlacementsMap['vol'] = decoPlacements_vol
decoPlacementsArrayMap['vol'] = decoPlacements_volArray
const decoPlacements = decoPlacementsMap[sportPrefix]
const decoPlacementsArray = decoPlacementsArrayMap[sportPrefix]
export default {
  vendors,
  garmentTypes,
  silhouettes,
  variants,
  pipings,
  fillAreas,
  fabrics,
  colors,
  graphicDesigns,
  graphicTextures,
  decoSizes,
  decoTypes,
  letteringFonts,
  letteringTails,
  letteringLayouts,
  rosterSizes,
  garments,
  decos,
  decoPlacements,
}

export const sheetLists = {
  vendors: vendorsArray,
  garmentTypes: garmentTypesArray,
  silhouettes: silhouettesArray,
  variants: variantsArray,
  pipings: pipingsArray,
  fillAreas: fillAreasArray,
  fabrics: fabricsArray,
  colors: colorsArray,
  graphicDesigns: graphicDesignsArray,
  graphicTextures: graphicTexturesArray,
  decoSizes: decoSizesArray,
  decoTypes: decoTypesArray,
  letteringFonts: letteringFontsArray,
  letteringTails: letteringTailsArray,
  letteringLayouts: letteringLayoutsArray,
  rosterSizes: rosterSizesArray,
  garments: garmentsArray,
  decos: decosArray,
  decoPlacements: decoPlacementsArray,
}

export {
  vendorsArray,
  garmentTypesArray,
  silhouettesArray,
  variantsArray,
  pipingsArray,
  fillAreasArray,
  fabricsArray,
  colorsArray,
  graphicDesignsArray,
  graphicTexturesArray,
  decoSizesArray,
  decoTypesArray,
  letteringFontsArray,
  letteringTailsArray,
  letteringLayoutsArray,
  rosterSizesArray,
  garmentsArray,
  decosArray,
  decoPlacementsArray,
}

