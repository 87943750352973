import Bluebird from 'bluebird'
// Required for touch camera input on mobile
import 'handjs'
import mitt from 'mitt'
import BABYLON from 'babylonjs'

import createScene from './createScene'

const meshHasChanged = (productConfig, nextProductConfig) => {
  const meshUrl = productConfig.assetUrls.rootUrl + productConfig.assetUrls.mesh
  const nextMeshUrl =
    nextProductConfig.assetUrls.rootUrl + nextProductConfig.assetUrls.mesh
  return meshUrl !== nextMeshUrl
}

const assetUrlHasChanged = (key) => (productConfig, nextProductConfig) => {
  const url = productConfig.assetUrls[key]
  const nextUrl = nextProductConfig.assetUrls[key]
  return url !== nextUrl
}

const outsideDiffuseMapHasChanged = assetUrlHasChanged('outsideDiffuseMap')
const insideDiffuseMapHasChanged = assetUrlHasChanged('insideDiffuseMap')

const cameraStateHasChanged = (sceneConfig, nextSceneConfig) => {
  const { azimuthal, polar, zoom, interactivityMode } = sceneConfig.cameraState

  const {
    azimuthal: nextAzimuthal,
    polar: nextPolar,
    zoom: nextZoom,
    interactivityMode: nextInteractivtyMode,
  } = nextSceneConfig.cameraState

  if (nextInteractivtyMode === 'free') {
    return false
  }

  if (interactivityMode === 'free' && nextInteractivtyMode !== 'free') {
    return true
  }

  return azimuthal !== nextAzimuthal || polar !== nextPolar || zoom !== nextZoom
}

const getZoomFactor = (camera) =>
  1 -
  (camera.radius - camera.lowerRadiusLimit) /
    (camera.upperRadiusLimit - camera.lowerRadiusLimit)

const getRadius = (zoomFactor, camera) => {
  const { lowerRadiusLimit: min, upperRadiusLimit: max } = camera
  return (1 - zoomFactor) * (max - min) + min
}

const BASE_ANGLE = Math.PI / 2

export default class Render3dEngine {
  getPeekDistanceBase = () => {
    const polarAngleFactor =
      this.camera.beta >= BASE_ANGLE ?
        (this.camera.beta - BASE_ANGLE) /
        (this.camera.upperBetaLimit - BASE_ANGLE)
      : ((this.camera.beta - BASE_ANGLE) /
          (this.camera.lowerBetaLimit - BASE_ANGLE)) *
        -1

    return polarAngleFactor < 0 ?
        this.props.scene.defaults.orbitControls.topPeekDistance *
          polarAngleFactor
      : this.props.scene.defaults.orbitControls.bottomPeekDistance *
          polarAngleFactor
  }

  renderSceneFromLoop = () => {
    if (
      this.camera.inertialAlphaOffset !== 0 ||
      this.camera.inertialBetaOffset !== 0 ||
      this.camera.inertialRadiusOffset !== 0
    ) {
      this.renderScene()
    }
  }

  renderScene = () => {
    this.camera.targetScreenOffset.y =
      this.getPeekDistanceBase() * getZoomFactor(this.camera)
    this.scene.render()
  }

  setCameraRadius = (zoom) => {
    this.camera.radius = getRadius(zoom, this.camera)
  }

  updateCameraPosition = (cameraState) => {
    const { azimuthal, polar, zoom } = cameraState
    this.camera.alpha = azimuthal
    this.camera.beta = polar
    this.setCameraRadius(zoom)
    this.renderScene()
  }

  getCameraState = () => ({
    azimuthal: this.camera.alpha,
    polar: this.camera.beta,
    zoom: getZoomFactor(this.camera),
  })

  loadMesh = (productConfig) => {
    const { assetUrls, position, scale } = productConfig
    const meshName = assetUrls.rootUrl + assetUrls.mesh

    if (this._meshes[meshName]) {
      return Promise.resolve(this._meshes[meshName])
    }

    return new Promise((resolve, reject) => {
      const task = new BABYLON.MeshAssetTask(
        meshName,
        '',
        assetUrls.rootUrl,
        assetUrls.mesh,
      )

      const onSuccess = () => {
        const meshes = task.loadedMeshes.concat()

        meshes.forEach((mesh) => {
          this.scene.removeMesh(mesh)
        })
        this._meshes[meshName] = meshes

        const material = this.createMaterial(`${meshName}: material`)

        meshes.forEach((mesh) => {
          mesh.scaling = new BABYLON.Vector3(scale, scale, scale)
          mesh.material = material
          mesh.setAbsolutePosition(
            new BABYLON.Vector3(position.x, position.y, position.z),
          )
        })

        Promise.all([
          this.loadTexture(assetUrls.rootUrl + assetUrls.normalMap),
          this.loadTexture(assetUrls.rootUrl + assetUrls.aoMap),
        ])
          .then(([normal, ao]) => {
            material.occlusionTexture = ao
            material.occlusionStrength = 0.88
            material.normalTexture = normal

            resolve(meshes)
          })
          .catch(() => {
            reject(
              new Error(
                'Failed loading one of model-dependent assets (normals|ao|reflectivity)',
              ),
            )
          })
      }

      const onError = () => {
        reject(
          new Error(
            `Failed loading model mesh ${assetUrls.rootUrl}${assetUrls.mesh}`,
          ),
        )
      }

      task.run(this.scene, onSuccess, onError)
    })
  }

  loadTexture = (url) => {
    const task = new BABYLON.TextureAssetTask(url, url)

    return new Promise((resolve, reject) => {
      const onSuccess = () => {
        resolve(task.texture)
      }

      const onError = () => {
        reject(new Error(`Failed loading texture ${url}`))
      }

      task.run(this.scene, onSuccess, onError)
    })
  }

  loadOutsideDiffuseMap = (productConfig) =>
    this.loadTexture(productConfig.assetUrls.outsideDiffuseMap)

  loadInsideDiffuseMap = (productConfig) =>
    this.loadTexture(productConfig.assetUrls.insideDiffuseMap)

  loadProduct = (productConfig) => {
    const pendingBluebird = this._pending.product
    if (pendingBluebird) {
      pendingBluebird.cancel()
    }

    let promise = Bluebird.all([
      this.loadMesh(productConfig),
      this.loadOutsideDiffuseMap(productConfig),
      this.loadInsideDiffuseMap(productConfig),
    ])
    this._pending.product = promise

    promise = promise.then(([meshes, outside, inside]) => {
      meshes.forEach((mesh) => {
        mesh.material.baseTexture = outside

        if (mesh.name.indexOf('inside') !== -1) {
          const insideMat = mesh.material.clone('insideMat')
          insideMat.baseTexture = inside
          mesh.material = insideMat
        } else if (mesh.name.indexOf('buttons') !== -1) {
          const buttonMat = mesh.material.clone('buttonMat')
          buttonMat.roughness = 0.3
          buttonMat.alpha = 0.5
          buttonMat.normalTexture = null
          mesh.material = buttonMat
        }
      })

      return meshes
    })

    // Prevents resolving within current execution frame, providing a brief window for cancellation.
    return promise.delay(0)
  }

  static shouldDisplayMesh = (productConfig, mesh) => {
    const smv = productConfig.subMeshVisibility[mesh.name]
    if (smv === undefined) return true
    return !!smv
  }

  compare = (nextProps) => {
    if (!this.props) {
      return { cameraState: true, assets: true, size: true }
    }

    const productConfig = this.props.scene.models[0]
    const nextProductConfig = nextProps.scene.models[0]

    const cameraState = cameraStateHasChanged(this.props.scene, nextProps.scene)
    const assets =
      meshHasChanged(productConfig, nextProductConfig) ||
      outsideDiffuseMapHasChanged(productConfig, nextProductConfig) ||
      insideDiffuseMapHasChanged(productConfig, nextProductConfig)
    const size =
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.height

    return { cameraState, assets, size }
  }

  render(nextProps) {
    const cmp = this.compare(nextProps)
    this.props = nextProps

    if (cmp.cameraState) {
      this.updateCameraPosition(this.props.scene.cameraState)
    }

    if (cmp.size) {
      this.resizeScene()
    }

    if (!cmp.assets) {
      return Promise.resolve()
    }

    const productConfig = this.props.scene.models[0]
    this.stopLoop()

    this._rendering = this.loadProduct(productConfig).then((meshes) => {
      // Remove previous meshes from the scene
      Object.keys(this._meshes).forEach((k) => {
        this._meshes[k].forEach((mesh) => {
          this.scene.removeMesh(mesh)
        })
      })

      // Add product meshes to the scene
      meshes.forEach((mesh) => {
        if (Render3dEngine.shouldDisplayMesh(productConfig, mesh)) {
          this.scene.addMesh(mesh)
        }
      })

      this.renderScene()
      this.startLoop()
    })
    return this._rendering
  }

  generatePreviews = (viewAngles) =>
    this._rendering.then(() => {
      const prevCameraState = this.getCameraState()
      const previews = {}

      this._engine.setSize(1024, 1024)
      this.renderScene()

      viewAngles.forEach((view) => {
        const cameraState = this.config.viewAngles[view]
        if (cameraState) {
          this.updateCameraPosition(this.config.viewAngles[view])
          this.renderScene()
          previews[view] = new Promise((resolve) => {
            this.canvas.toBlob(resolve)
          })
        }
      })

      this.updateCameraPosition(prevCameraState)
      this.resizeScene()

      return Bluebird.props(previews)
    })

  resizeScene = () => {
    const { width, height } = this.props
    if (width != null && height != null) {
      const { devicePixelRatio: ratio } = window
      this._engine.setSize(width * ratio, height * ratio)
      this.camera.fovMode =
        width < height ?
          BABYLON.Camera.FOVMODE_HORIZONTAL_FIXED
        : BABYLON.Camera.FOVMODE_VERTICAL_FIXED
    } else {
      this._engine.resize()
    }
    this.renderScene()
  }

  onVisibilityChange = () => {
    if (document.hidden) {
      this.stopLoop()
    } else {
      this.startLoop()
    }
  }

  constructor(config) {
    this.config = config
    this.canvas = document.createElement('canvas')
    this.canvas.style.touchAction = 'none'

    this._pending = {}
    this._meshes = {}

    this._engine = new BABYLON.Engine(this.canvas, true)

    this._engine.enableOfflineSupport = false

    const { scene, createMaterial, camera } = createScene(
      BABYLON,
      this.config.sceneDefaults,
      this._engine,
      this.canvas,
    )

    this.scene = scene
    this.createMaterial = createMaterial
    this.camera = camera

    this.events = mitt()

    this.pointerObserver = scene.onPointerObservable.add((info) => {
      const {
        POINTERDOWN: down,
        POINTERUP: up,
        POINTERMOVE: move,
        POINTERWHEEL: wheel,
      } = BABYLON.PointerEventTypes

      const userDidInteract =
        info.type === down ||
        info.type === up ||
        info.type === wheel ||
        (info.type === move && info.event.pressure > 0)

      if (userDidInteract) {
        this.events.emit('user_interaction')
      }
    })

    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  startLoop() {
    this._engine.runRenderLoop(this.renderSceneFromLoop)
  }

  stopLoop() {
    this._engine.stopRenderLoop(this.renderScene)
  }

  dispose() {
    this.scene.onPointerObservable.remove(this.pointerObserver)
    this.stopLoop()
    this.canvas.remove()
  }
}
