import React from 'react'

import { cn } from '~p/client/components/utils'

import ContentHead from '../atoms/ContentHead'
import ContentLabel from '../atoms/ContentLabel'
import ContentBody from '../atoms/ContentBody'

function StaticSection(props) {
  const { primaryLabel, children } = props
  return (
    <div className={cn(['section'])}>
      <ContentHead classMods={['section']}>
        <ContentLabel classMods={['section']}>
          <span>{primaryLabel}</span>
        </ContentLabel>
      </ContentHead>
      <ContentBody classMods={['section']}>{children}</ContentBody>
    </div>
  )
}

export default StaticSection
