import getAsset from '~p/getAsset'

export default function (BABYLON, sceneDefaults, engine, canvas) {
  const scene = new BABYLON.Scene(engine)
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0)
  // scene.ambientColor = new BABYLON.Color3(1, 1, 1)

  const { minPolarAngle, maxPolarAngle, minDistance, maxDistance } =
    sceneDefaults.orbitControls

  const { near, far, fov } = sceneDefaults.camera

  const camera = new BABYLON.ArcRotateCamera(
    'ArcRotateCamera',
    0,
    0,
    maxDistance,
    new BABYLON.Vector3(0, 0, 0),
    scene,
  )
  camera.attachControl(canvas)
  camera.lowerBetaLimit = minPolarAngle
  camera.upperBetaLimit = maxPolarAngle

  camera.upperRadiusLimit = maxDistance
  camera.lowerRadiusLimit = minDistance

  camera.minZ = near
  camera.maxZ = far
  camera.fov = fov
  camera.wheelPrecision = 160

  const light = new BABYLON.HemisphericLight(
    'light1',
    new BABYLON.Vector3(0, 1, 0),
    scene,
  )
  light.intensity = 0.5

  const ddsFile = getAsset('studio1HDR.dds')

  scene.environmentTexture = new BABYLON.CubeTexture(ddsFile, scene)
  const createMaterial = (name) => {
    const fabric = new BABYLON.PBRMetallicRoughnessMaterial(name, scene)
    fabric.baseColor = new BABYLON.Color3(1, 1, 1)
    fabric.metallic = 0.01
    fabric.roughness = 0.98

    return fabric
  }

  return { scene, createMaterial, camera }
}
