import fp from 'lodash/fp.js'
import { handleActions, concat } from 'redux-fp'

import controlTree from './controlTree'
import sheets from '../common/sheets'
import { updater as render3dUpdater } from './render3d'
import { defaultViewAngleId } from '../viewAngles'

const initialState = {
  garmentFilter: null,
  render3d: { activeView: defaultViewAngleId },
  closedBlocks: {
    ...(window.serverConfig.closedBlocksOverride || {}),
  },
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

const guaranteeSheets = () => (state) =>
  state.sheets ? state : fp.set('sheets', sheets)(state)

const uiUpdater = handleActions({
  SET_GARMENT_FILTER: ({ payload }) => fp.set('garmentFilter', payload),
})

export default concat(
  getDefaultState,
  guaranteeSheets,
  uiUpdater,
  controlTree.updater,
  (action) => fp.update('render3d', render3dUpdater(action)),
)
