/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "neck": {
    "name": "Neck",
    "id": "neck",
    "subsets": {
      "neck": true,
      "neckSleeve": true,
      "sideLoop": false
    },
    "asset": {
      "layerNames": [
        "*.pipingNeck"
      ],
      "useAreaDFor1Color": true,
      "reverseOrder": false
    }
  },
  "sleeve": {
    "name": "Sleeve",
    "id": "sleeve",
    "subsets": {
      "neck": false,
      "neckSleeve": true,
      "sideLoop": false
    },
    "asset": {
      "layerNames": [
        "*.pipingSleeves"
      ],
      "useAreaDFor1Color": true,
      "reverseOrder": true
    }
  },
  "side": {
    "name": "Side",
    "id": "side",
    "subsets": {
      "neck": false,
      "neckSleeve": false,
      "sideLoop": true
    },
    "asset": {
      "layerNames": [
        "*.pipingSide"
      ],
      "useAreaDFor1Color": true,
      "reverseOrder": false
    }
  },
  "loop": {
    "name": "Loop",
    "id": "loop",
    "subsets": {
      "neck": false,
      "neckSleeve": false,
      "sideLoop": true
    },
    "asset": {
      "layerNames": [
        "*.pipingLoop"
      ],
      "useAreaDFor1Color": true,
      "reverseOrder": true
    }
  }
}

const sheetArray = [
  sheet['neck'],
  sheet['sleeve'],
  sheet['side'],
  sheet['loop'],
]

export default sheet
export { sheetArray }
