import { handleActions, combine, concat } from 'redux-fp'

const activeView = concat(
  () =>
    (state = null) =>
      state,
  handleActions({
    SET_ACTIVE_VIEW: (action) => () => action.payload,
  }),
)

const interactivityMode = concat(
  () =>
    (state = 'user') =>
      state,
  handleActions({
    SET_ACTIVE_VIEW: () => () => 'user',
    SET_FREE_INTERACTIVITY_MODE: () => () => 'free',
  }),
)

const zoomMode = concat(
  () =>
    (state = 'auto') =>
      state,
  handleActions({
    SET_ZOOM_MODE: (action) => () => action.payload,
  }),
)

const isLoading = concat(
  () =>
    (state = false) =>
      state,
  handleActions({
    SET_VISUALIZATION_LOADING: (action) => () => action.payload,
  }),
)

export default combine({ activeView, interactivityMode, zoomMode, isLoading })
