import getAsset from '~p/getAsset'

import downgradeTo000 from '../common/modelMigrations/downgradeTo000'
import viewAngles from '../viewAngles'

const defaults = {
  orbitControls: {
    enabled: true,
    enableZoom: true,
    enableRotate: true,
    enablePan: false,
    enableDamping: true,
    dampingFactor: 0.1,
    minDistance: 2 / 3,
    maxDistance: 2,
    topPeekDistance: 0.3,
    bottomPeekDistance: 0.3,
    minPolarAngle: Math.PI / 2 - Math.PI / 2 / 4,
    maxPolarAngle: Math.PI / 2 + Math.PI / 2 / 4,
  },
  camera: {
    near: 0.1,
    far: 10,
    fov: (27 / 180) * Math.PI, // Camera frustum vertical field of view
  },
}

function getAssetRootUrl(assetFolder, localFileUrl) {
  const fileUrl = getAsset(assetFolder + localFileUrl)
  if (!fileUrl) {
    throw new Error(
      `Failed to locate asset url for ${assetFolder + localFileUrl}`,
    )
  }

  const folderPath = `/assets/${assetFolder}`
  const folderPathIndex = fileUrl.indexOf(folderPath)
  return fileUrl.substr(0, folderPathIndex + folderPath.length)
}

function getAssetPath(assetFolder, rootUrl, localFileUrl) {
  const fileUrl = getAsset(assetFolder + localFileUrl)
  if (!fileUrl) {
    throw new Error(
      `Failed to locate asset url for ${assetFolder + localFileUrl}`,
    )
  }

  return fileUrl.replace(rootUrl, '')
}

function get2dRenderUrl(valueRows, isInside = false) {
  const size = 2048
  const textureSide = isInside ? 'inside' : 'outside'
  const productId = `${valueRows.garment.silhouetteId}-${valueRows.garment.variantId}`
  const recipeProxyData = downgradeTo000(valueRows)

  const query = {
    size: { width: size, height: size },
    textureSide,
    productId,
    design: recipeProxyData,
    // debugSVG: true,
  }

  return `/api/render2d?q=${encodeURIComponent(JSON.stringify(query))}`
}

function getAssetUrls(valueRows) {
  const { garment } = valueRows

  const variantPostfix =
    (
      garment.sportId === 'softball' &&
      garment.garmentTypeId === 'jersey' &&
      (valueRows['design.designType'] === 'panel' ||
        (garment.variantId === 'sleeveless' &&
          valueRows['design.graphicDesign'].id === 'checks'))
    ) ?
      '-panel'
    : ''

  const assetFolder = `${garment.sportId}/${garment.garmentTypeId}/`
  const modelName = `${garment.asset.modelId}${variantPostfix}`
  const rootUrl = getAssetRootUrl(assetFolder, `${modelName}.babylon`)

  return {
    // mesh, normalMap and aoMap depend on rootUrl
    rootUrl,
    mesh: getAssetPath(assetFolder, rootUrl, `${modelName}.babylon`),
    normalMap: getAssetPath(assetFolder, rootUrl, `${modelName}_normals.png`),
    aoMap: getAssetPath(
      assetFolder,
      rootUrl,
      `${modelName}_ambientOcclusions.jpg`,
    ),
    // diffuseMaps are absolute urls to 2d renderer
    outsideDiffuseMap: get2dRenderUrl(valueRows),
    insideDiffuseMap: get2dRenderUrl(valueRows, true),
  }
}

export default (valueRows, viewState = {}) => {
  const isPant = valueRows.garmentType.id === 'pant'
  return {
    defaults,
    cameraState: {
      ...viewAngles[viewState.activeView],
      interactivityMode: viewState.interactivityMode,
    },
    models: [
      {
        id: 'jersey-or-pant haha',
        scale: isPant ? 0.75 : 1,
        position: {
          x: 0,
          y: 0.05,
          z: 0,
        },
        assetUrls: getAssetUrls(valueRows),
        subMeshVisibility: {
          panel:
            isPant ? valueRows['design.designType'] === 'panel' : undefined,
        },
      },
    ],
  }
}
