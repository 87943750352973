import { getGarmentTypeName } from '~p/common/meta'

import sheets from '~c/common/sheets'

export const getIndexTitle = (config) =>
  `${config.titleBase} ${config.titlePostfix}` +
  `${config.hideVia ? '' : ` via ${config.vendorName}`}`

export const getIndexDescription = (config) => config.getIndexDescription()

export const getSkuValues = (sku) => {
  const garment = sheets.garments[sku]

  if (!garment) {
    throw new Error(`SKU is not valid: "${sku}"`)
  }

  return {
    garment,
    canonicalSku: garment.id,
  }
}

export const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { garment } = getSkuValues(sku)
  const garmentType = getGarmentTypeName(garment.id)
  return (
    `${garment.name} - ${config.titleBase} ${garmentType} ` +
    `Template${config.hideVia ? '' : ` via ${config.vendorName}`}`
  )
}

export const getSkuDescription = (config, params) => {
  const garmentType = getGarmentTypeName(params[0]).toLowerCase()
  return config.getSkuDescription({ garmentType })
}

export const getRecipeTitle = (config, recipe, id, hasChanges) => {
  const garmentType = getGarmentTypeName(recipe.data.garment)
  return (
    `${id}${hasChanges ? ' with changes' : ''} - ` +
    `${config.titleBase} ${garmentType}` +
    `${config.hideVia ? '' : ` via ${config.vendorName}`}`
  )
}

export const getRecipeDescription = (config, recipe) => {
  const garmentType = getGarmentTypeName(recipe.data.garment).toLowerCase()
  return config.getRecipeDescription({ garmentType })
}

export const getStaticTags = () => ''
