/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "jet": {
    "name": "Jet",
    "id": "jet",
    "subsets": {
      "all": true,
      "sweep": false
    }
  },
  "sweep": {
    "name": "Sweep",
    "id": "sweep",
    "subsets": {
      "all": true,
      "sweep": true
    }
  },
  "standard": {
    "name": "Standard",
    "id": "standard",
    "subsets": {
      "all": true,
      "sweep": false
    }
  },
  "stanford": {
    "name": "Stanford",
    "id": "stanford",
    "subsets": {
      "all": true,
      "sweep": false
    }
  }
}

const sheetArray = [
  sheet['jet'],
  sheet['sweep'],
  sheet['standard'],
  sheet['stanford'],
]

export default sheet
export { sheetArray }
