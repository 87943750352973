/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "mizunousa.volleyballuniform.mizunocustom.com": {
    "hostname": "mizunousa.volleyballuniform.mizunocustom.com",
    "environment": "production",
    "sportId": "volleyball",
    "vendorId": "mizunousa",
    "vendorName": null,
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MizunoUSA Sublimated orders",
    "addToCartUrl": null,
    "twitterHandle": "MizunoVolley",
    "parentHost": "www.mizunousa.com/category/volleyball+content/custom+jersey+builder.do#!",
    "hideVia": true,
    "customerEmailOrderBodyId": "mizunousa",
    "regions": "usa"
  },
  "volleyballuniform.mizunocustom.com": {
    "hostname": "volleyballuniform.mizunocustom.com",
    "environment": "production",
    "sportId": "volleyball",
    "vendorId": "mizunousa",
    "vendorName": null,
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MizunoUSA Sublimated orders",
    "addToCartUrl": null,
    "twitterHandle": "MizunoVolley",
    "parentHost": "www.mizunousa.com/category/volleyball+content/custom+jersey+builder.do#!",
    "hideVia": true,
    "customerEmailOrderBodyId": "mizunousa",
    "regions": "usa"
  },
  "volleyballjersey.mizunocustom.com": {
    "hostname": "volleyballjersey.mizunocustom.com",
    "environment": "production",
    "sportId": "volleyball",
    "vendorId": "mizunousa",
    "vendorName": null,
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MizunoUSA Sublimated orders",
    "addToCartUrl": null,
    "twitterHandle": "MizunoVolley",
    "parentHost": "www.mizunousa.com/category/volleyball+content/custom+jersey+builder.do#!",
    "hideVia": true,
    "customerEmailOrderBodyId": "mizunousa",
    "regions": "usa"
  },
  "vbu-staging.mizunocustom.com": {
    "hostname": "vbu-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "volleyball",
    "vendorId": "mizunousa",
    "vendorName": null,
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MizunoUSA Sublimated orders",
    "addToCartUrl": null,
    "twitterHandle": "MizunoVolley",
    "parentHost": "www.mizunousa.com/category/volleyball+content/custom+jersey+builder.do#!",
    "hideVia": true,
    "customerEmailOrderBodyId": "mizunousa",
    "regions": "usa"
  },
  "mizunocda.volleyballuniform.mizunocustom.com": {
    "hostname": "mizunocda.volleyballuniform.mizunocustom.com",
    "environment": "production",
    "sportId": "volleyball",
    "vendorId": "mizunocda",
    "vendorName": null,
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Canada Custom orders",
    "addToCartUrl": null,
    "twitterHandle": "MizunoVolley",
    "parentHost": null,
    "hideVia": true,
    "customerEmailOrderBodyId": null,
    "regions": "canada"
  },
  "allvolleyball.volleyballuniform.mizunocustom.com": {
    "hostname": "allvolleyball.volleyballuniform.mizunocustom.com",
    "environment": "production",
    "sportId": "volleyball",
    "vendorId": "allvolleyball",
    "vendorName": "All Volleyball",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": null,
    "addToCartUrl": null,
    "twitterHandle": "allvolleyball",
    "parentHost": null,
    "hideVia": false,
    "customerEmailOrderBodyId": null
  }
}

const sheetArray = [
  sheet['mizunousa.volleyballuniform.mizunocustom.com'],
  sheet['volleyballuniform.mizunocustom.com'],
  sheet['volleyballjersey.mizunocustom.com'],
  sheet['vbu-staging.mizunocustom.com'],
  sheet['mizunocda.volleyballuniform.mizunocustom.com'],
  sheet['allvolleyball.volleyballuniform.mizunocustom.com'],
]

export default sheet
export { sheetArray }
