/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "body": {
    "name": "Body",
    "id": "body",
    "isRequired": true,
    "defaults": {
      "colors": {
        "bbsb": [
          "royal",
          "white",
          "black"
        ],
        "vol": [
          "royal",
          "white",
          "black"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": true,
      "fullCustomPant": true,
      "semiCustomPant": true,
      "replicaJersey": false,
      "sleevelessJersey": true
    }
  },
  "sleeve": {
    "name": "Sleeve",
    "id": "sleeve",
    "isRequired": true,
    "defaults": {
      "colors": {
        "bbsb": [
          "white",
          "black",
          "royal"
        ],
        "vol": [
          "royal",
          "white black"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": true,
      "fullCustomPant": false,
      "semiCustomPant": false,
      "replicaJersey": false,
      "sleevelessJersey": false
    }
  },
  "collar": {
    "name": "Collar",
    "id": "collar",
    "isRequired": false,
    "defaults": {
      "colors": {
        "bbsb": [
          "black",
          "royal",
          "white"
        ],
        "vol": [
          "white",
          "black",
          "royal"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": true,
      "fullCustomPant": false,
      "semiCustomPant": false,
      "replicaJersey": false,
      "sleevelessJersey": true
    }
  },
  "mizunoLogo": {
    "name": "Mizuno Logo",
    "id": "mizunoLogo",
    "isRequired": true,
    "defaults": {
      "colors": {
        "bbsb": [
          "white",
          "black",
          "royal"
        ],
        "vol": [
          "white",
          "black",
          "royal"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": true,
      "fullCustomPant": true,
      "semiCustomPant": false,
      "replicaJersey": false,
      "sleevelessJersey": true
    }
  },
  "loop": {
    "name": "Loop",
    "id": "loop",
    "isRequired": true,
    "defaults": {
      "colors": {
        "bbsb": [
          "black",
          "royal",
          "white"
        ],
        "vol": [
          "black",
          "royal",
          "white"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": false,
      "fullCustomPant": true,
      "semiCustomPant": false,
      "replicaJersey": false,
      "sleevelessJersey": false
    }
  },
  "pocket": {
    "name": "Pocket",
    "id": "pocket",
    "isRequired": false,
    "defaults": {
      "colors": {
        "bbsb": [
          "black",
          "royal",
          "white"
        ],
        "vol": [
          "black",
          "royal",
          "white"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": null,
      "ifSolidPanelDesign": false
    },
    "subsets": {
      "regularJersey": false,
      "fullCustomPant": true,
      "semiCustomPant": false,
      "replicaJersey": false,
      "sleevelessJersey": false
    }
  },
  "panel": {
    "name": "Panel",
    "id": "panel",
    "isRequired": true,
    "defaults": {
      "colors": {
        "bbsb": [
          "pantGray",
          "black",
          "royal"
        ],
        "vol": [
          "white",
          "black",
          "royal"
        ]
      }
    },
    "limitations": {
      "colorSubsetId": "all",
      "ifSolidPanelDesign": true
    },
    "subsets": {
      "regularJersey": false,
      "fullCustomPant": true,
      "semiCustomPant": true,
      "replicaJersey": false,
      "sleevelessJersey": false
    }
  }
}

const sheetArray = [
  sheet['body'],
  sheet['sleeve'],
  sheet['collar'],
  sheet['mizunoLogo'],
  sheet['loop'],
  sheet['pocket'],
  sheet['panel'],
]

export default sheet
export { sheetArray }
