import { createAction } from 'redux-actions'

export const setActiveView = createAction('SET_ACTIVE_VIEW')
export const setFreeInteractivityMode = createAction(
  'SET_FREE_INTERACTIVITY_MODE',
)
export const setZoomMode = createAction('SET_ZOOM_MODE')
export const setLoading = createAction(
  'SET_VISUALIZATION_LOADING',
  (payload) => !!payload,
)
