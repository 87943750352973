/* eslint-disable */

// This is generated by a script, see platform/common/importer




const sheet = {
  "fullButton-setIn": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "fullButton",
    "variantId": "setIn",
    "id": "fullButton-setIn",
    "name": "Full Button Set-in Sleeve",
    "asset": {
      "modelId": "fullButton-setIn",
      "textureId": "fullButton-setIn"
    },
    "limitations": {
      "fabricSubsetId": "fullButtonJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "fullButton-sleeveless": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "fullButton",
    "variantId": "sleeveless",
    "id": "fullButton-sleeveless",
    "name": "Full Button Sleeveless",
    "asset": {
      "modelId": "fullButton-sleeveless",
      "textureId": "fullButton-sleeveless"
    },
    "limitations": {
      "fabricSubsetId": "fullButtonJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "sleevelessJersey",
      "decoSubsetId": "bbsbSleevelessJersey",
      "pipingSubsetId": "neck",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "2button-setIn": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "2button",
    "variantId": "setIn",
    "id": "2button-setIn",
    "name": "2 Button Set-In Sleeve",
    "asset": {
      "modelId": "2button-setIn",
      "textureId": "2button-setIn"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "2button-sleeveless": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "2button",
    "variantId": "sleeveless",
    "id": "2button-sleeveless",
    "name": "2 Button Sleeveless",
    "asset": {
      "modelId": "2button-sleeveless",
      "textureId": "2button-sleeveless"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "sleevelessJersey",
      "decoSubsetId": "bbsbSleevelessJersey",
      "pipingSubsetId": "neck",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "vNeck-setIn": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "vNeck",
    "variantId": "setIn",
    "id": "vNeck-setIn",
    "name": "V-Neck Set-In Sleeve",
    "asset": {
      "modelId": "vNeck-setIn",
      "textureId": "vNeck-setIn"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "vNeck-sleeveless": {
    "sportId": "softball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "vNeck",
    "variantId": "sleeveless",
    "id": "vNeck-sleeveless",
    "name": "V-Neck Sleeveless",
    "asset": {
      "modelId": "vNeck-sleeveless",
      "textureId": "vNeck-sleeveless"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "softballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "sleevelessJersey",
      "decoSubsetId": "bbsbSleevelessJersey",
      "pipingSubsetId": "neck",
      "roasterSizeSubsetId": "jerseys"
    }
  }
}

const sheetArray = [
  sheet['fullButton-setIn'],
  sheet['fullButton-sleeveless'],
  sheet['2button-setIn'],
  sheet['2button-sleeveless'],
  sheet['vNeck-setIn'],
  sheet['vNeck-sleeveless'],
]

export default sheet
export { sheetArray }
