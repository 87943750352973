
import fp from 'lodash/fp.js'
import sheets from '../sheets'

export default function(expandedRecipe) {
  function getId(path, fallbackValue) {
    const item = expandedRecipe[path]
    if (item == null) {
      return fallbackValue || 'none'
    }
    return item.id
  }

  function getDesignNodes() {
    const graphicDesignId = getId('design.graphicDesign')
    if (graphicDesignId === 'solidPanel') {
      return {
        type: graphicDesignId,
        accent_1: getFillNodes('fillAreas.panel'),
        accent_2: {
          pattern: 'none',
          color_1: 'none',
          color_2: 'none',
          color_3: 'none',
          color_4: 'none',
        },
        accent_3: {
          pattern: 'none',
          color_1: 'none',
          color_2: 'none',
          color_3: 'none',
          color_4: 'none',
        },
      }
    }

    return {
      type: graphicDesignId,
      accent_1: {
        pattern: 'solidColor',
        color_1: getId('design.accent1'),
        color_2: 'none',
        color_3: 'none',
        color_4: 'none',
      },
      accent_2: {
        pattern: 'solidColor',
        color_1: getId('design.accent2'),
        color_2: 'none',
        color_3: 'none',
        color_4: 'none',
      },
      accent_3: {
        pattern: 'solidColor',
        color_1: getId('design.accent3'),
        color_2: 'none',
        color_3: 'none',
        color_4: 'none',
      },
    }
  }

  function getFillNodes(path) {
    let pattern = getId(`${path}.graphicTexture`)
    if (pattern === 'solid') {
      pattern = 'solidColor'
    }

    return {
      pattern,
      color_1: getId(`${path}.color1`),
      color_2: getId(`${path}.color2`),
      color_3: getId(`${path}.color3`),
      color_4: getId(`${path}.color4`),
    }
  }

  function getMizunoLogoFillNodes(logoColorPath, bodyColorPath) {
    const pattern = 'solidColor'
    let color_1 = getId(`${logoColorPath}.color1`)

    if (color_1 === 'none') {
      const baseColor = sheets.colors[getId(`${bodyColorPath}.color1`, 'navy')]
      const garmentType = fp.startCase(getId('garmentType'))
      color_1 =
        baseColor.altColorIds[`volleyball${garmentType}Logo`] || 'white'
    }

    return {
      pattern,
      color_1,
      color_2: 'none',
      color_3: 'none',
      color_4: 'none',
    }
  }

  function getDecoBaseNodes(path) {
    return {
      placement: getId(`${path}.position`),
      size: getId(`${path}.size`, undefined),
    }
  }

  function getDecoNumberNodes(path) {
    return {
      ...getDecoBaseNodes(path),

      text: expandedRecipe[`${path}.content.text`] || '',
      font: getId(`${path}.content.font`, 'script'),
      fill: getFillNodes(`${path}.content.fill`),
      outline_1: getId(`${path}.content.outlineColor1`),
      outline_2: getId(`${path}.content.outlineColor2`),
    }
  }

  function getDecoTextNodes(path) {
    return {
      ...getDecoNumberNodes(path),

      layout: getId(`${path}.content.layout`),
    }
  }

  function getDecoTextWithTailingNodes(path) {
    return {
      ...getDecoTextNodes(path),

      tailing: {
        enabled: getId(`${path}.content.tailEnabled`),
        type: getId(`${path}.content.tailShape`),
        text: expandedRecipe[`${path}.content.tailText`] || '',
        font: getId(`${path}.content.tailTextFont`, 'script'),
        fill: getId(`${path}.content.tailTextColor`),
      },
    }
  }

  function getFileUrl(value) {
    if (value == null) {
      return ''
    }
    return `${window.location.origin}/api/images/${value.id}`
  }

  function getDecoLogoNodes(path) {
    return {
      ...getDecoBaseNodes(path),

      customFile:
        getId(`${path}.position`) !== 'none'
          ? getFileUrl(expandedRecipe[`${path}.content.previewImage`])
          : '',
      factoryFile: '',
    }
  }

  function getPipingNodes(path) {
    const defaults = {
      neck: 'off',
      sleeve: 'off',
      side: 'off',
      loop: 'off',
    }

    const positions = fp.map('id')(expandedRecipe[`${path}.positions`])
    const positionObject = fp.fromPairs(fp.map(x => [x, 'on'], positions))

    return fp.assign(defaults, {
      ...positionObject,
      color_1: getId(`${path}.content.color1`),
      color_2: getId(`${path}.content.color2`),
      color_3: getId(`${path}.content.color3`),
    })
  }

  return {
    designTemplate: `${expandedRecipe.garment.silhouetteId}-${expandedRecipe
      .garment.variantId}`,

    silhouette: expandedRecipe.garment.silhouetteId,
    variant: expandedRecipe.garment.variantId,
    fabricType: expandedRecipe.fabric.id,

    design: getDesignNodes(),

    colorAreas: {
      body: getFillNodes('fillAreas.body'),
      sleeves: getFillNodes('fillAreas.sleeve'),
      collar: getFillNodes('fillAreas.collar'),
      pocket: getFillNodes('fillAreas.pocket'),
      loop: getFillNodes('fillAreas.loop'),
      logo: getMizunoLogoFillNodes('fillAreas.mizunoLogo', 'fillAreas.body'),
    },

    decoration: {
      teamName: getDecoTextWithTailingNodes('decos.teamName'),

      playerName: getDecoTextWithTailingNodes('decos.playerName'),

      logo: {
        front: getDecoLogoNodes('decos.frontLogo'),
        sleeve: getDecoLogoNodes('decos.sleeveLogo'),
        leftSleeve: getDecoLogoNodes('decos.leftSleeveLogo'),
        rightSleeve: getDecoLogoNodes('decos.rightSleeveLogo'),
        back: getDecoLogoNodes('decos.backLogo'),
      },

      number: {
        front: getDecoNumberNodes('decos.frontNumber'),
        back: getDecoNumberNodes('decos.backNumber'),
      },

      piping: getPipingNodes('piping'),

      specialInstructions: '',
    },
  }
}
