/* eslint-disable */

// This is generated by a script, see platform/common/importer




const sheet = {
  "fullButton-setIn": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "fullButton",
    "variantId": "setIn",
    "id": "fullButton-setIn",
    "name": "Full Button Set-in Sleeve",
    "asset": {
      "modelId": "fullButton-setIn",
      "textureId": "fullButton-setIn"
    },
    "limitations": {
      "fabricSubsetId": "fullButtonJersey",
      "designSubsetId": "baseballJerseyWithoutSpider",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "fullButton-sleeveless": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "fullButton",
    "variantId": "sleeveless",
    "id": "fullButton-sleeveless",
    "name": "Full Button Sleeveless",
    "asset": {
      "modelId": "fullButton-sleeveless",
      "textureId": "fullButton-sleeveless"
    },
    "limitations": {
      "fabricSubsetId": "fullButtonJersey",
      "designSubsetId": "baseballJerseyWithoutSpiderAndChecks",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "sleevelessJersey",
      "decoSubsetId": "bbsbSleevelessJersey",
      "pipingSubsetId": "neck",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "fullButton-raglan": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "fullButton",
    "variantId": "raglan",
    "id": "fullButton-raglan",
    "name": "Full Button Raglan Sleeve",
    "asset": {
      "modelId": "fullButton-raglan",
      "textureId": "fullButton-raglan"
    },
    "limitations": {
      "fabricSubsetId": "fullButtonJersey",
      "designSubsetId": "baseballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "2button-setIn": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "2button",
    "variantId": "setIn",
    "id": "2button-setIn",
    "name": "2 Button Set-In Sleeve",
    "asset": {
      "modelId": "2button-setIn",
      "textureId": "2button-setIn"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "baseballJerseyWithoutSpider",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "2button-raglan": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "2button",
    "variantId": "raglan",
    "id": "2button-raglan",
    "name": "2 Button Raglan Sleeve",
    "asset": {
      "modelId": "2button-raglan",
      "textureId": "2button-raglan"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "baseballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "crewPullover-setIn": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "crewPullover",
    "variantId": "setIn",
    "id": "crewPullover-setIn",
    "name": "Crewneck Set-In Sleeve",
    "asset": {
      "modelId": "crewPullover-setIn",
      "textureId": "crewPullover-setIn"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "baseballJerseyWithoutSpider",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  },
  "crewPullover-raglan": {
    "sportId": "baseball",
    "garmentGroupId": "jersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "crewPullover",
    "variantId": "raglan",
    "id": "crewPullover-raglan",
    "name": "Crewneck Raglan Sleeve",
    "asset": {
      "modelId": "crewPullover-raglan",
      "textureId": "crewPullover-raglan"
    },
    "limitations": {
      "fabricSubsetId": "regularJersey",
      "designSubsetId": "baseballJersey",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "bbsbJersey",
      "pipingSubsetId": "neckSleeve",
      "roasterSizeSubsetId": "jerseys"
    }
  }
}

const sheetArray = [
  sheet['fullButton-setIn'],
  sheet['fullButton-sleeveless'],
  sheet['fullButton-raglan'],
  sheet['2button-setIn'],
  sheet['2button-raglan'],
  sheet['crewPullover-setIn'],
  sheet['crewPullover-raglan'],
]

export default sheet
export { sheetArray }
