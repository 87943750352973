/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "teamName": {
    "sportPrefix": "bbsb",
    "id": "teamName",
    "uniqueId": "bbsb-teamName",
    "name": "Team Name",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "fullFront",
      "size": null,
      "text": "Teamname",
      "font": "script"
    },
    "limitations": {
      "contentTypeId": "textWithTailing",
      "letteringFontSubsetId": "bbsb-all",
      "maxCharCount": null,
      "decoPlacementIds": [
        "fullFront",
        "leftChest"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "playerName": {
    "sportPrefix": "bbsb",
    "id": "playerName",
    "uniqueId": "bbsb-playerName",
    "name": "Player Name",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "upperBack",
      "size": null,
      "text": "Player Name",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "text",
      "letteringFontSubsetId": "bbsb-all",
      "maxCharCount": null,
      "decoPlacementIds": [
        "upperBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "frontLogo": {
    "sportPrefix": "bbsb",
    "id": "frontLogo",
    "uniqueId": "bbsb-frontLogo",
    "name": "Front Logo",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "leftChest",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "fullFront",
        "leftChest"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "leftSleeveLogo": {
    "sportPrefix": "bbsb",
    "id": "leftSleeveLogo",
    "uniqueId": "bbsb-leftSleeveLogo",
    "name": "Left Sleeve Logo",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "sleeveLeft",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "sleeveLeft"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "rightSleeveLogo": {
    "sportPrefix": "bbsb",
    "id": "rightSleeveLogo",
    "uniqueId": "bbsb-rightSleeveLogo",
    "name": "Right Sleeve Logo",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "sleeveRight",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "sleeveRight"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "backLogo": {
    "sportPrefix": "bbsb",
    "id": "backLogo",
    "uniqueId": "bbsb-backLogo",
    "name": "Back Logo",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "upperBack",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "upperBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "frontNumber": {
    "sportPrefix": "bbsb",
    "id": "frontNumber",
    "uniqueId": "bbsb-frontNumber",
    "name": "Front Number",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "frontLeft",
      "size": null,
      "text": "23",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "number",
      "letteringFontSubsetId": "bbsb-number",
      "maxCharCount": 2,
      "decoPlacementIds": [
        "frontLeft",
        "frontRight"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "backNumber": {
    "sportPrefix": "bbsb",
    "id": "backNumber",
    "uniqueId": "bbsb-backNumber",
    "name": "Back Number",
    "subsets": {
      "bbsbJersey": true,
      "bbsbSleevelessJersey": true,
      "bbsbPant": false,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "fullBack",
      "size": null,
      "text": "23",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "number",
      "letteringFontSubsetId": "bbsb-number",
      "maxCharCount": 2,
      "decoPlacementIds": [
        "fullBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "pantNumber": {
    "sportPrefix": "bbsb",
    "id": "pantNumber",
    "uniqueId": "bbsb-pantNumber",
    "name": "Number",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": true,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "frontRightPant",
      "size": null,
      "text": "23",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "number",
      "letteringFontSubsetId": "bbsb-number",
      "maxCharCount": null,
      "decoPlacementIds": [
        "frontLeftPant",
        "frontRightPant"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "pantTeamName": {
    "sportPrefix": "bbsb",
    "id": "pantTeamName",
    "uniqueId": "bbsb-pantTeamName",
    "name": "Team Name",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": true,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "frontLeftPant",
      "size": null,
      "text": "Teamname",
      "font": "script"
    },
    "limitations": {
      "contentTypeId": "textWithTailing",
      "letteringFontSubsetId": "bbsb-all",
      "maxCharCount": null,
      "decoPlacementIds": [
        "frontLeftPant",
        "frontRightPant"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "pantLogo": {
    "sportPrefix": "bbsb",
    "id": "pantLogo",
    "uniqueId": "bbsb-pantLogo",
    "name": "Logo",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": true,
      "volleyballJersey": false,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "frontLeftPant",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "frontLeftPant",
        "frontRightPant"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  }
}

const sheetArray = [
  sheet['teamName'],
  sheet['playerName'],
  sheet['frontLogo'],
  sheet['leftSleeveLogo'],
  sheet['rightSleeveLogo'],
  sheet['backLogo'],
  sheet['frontNumber'],
  sheet['backNumber'],
  sheet['pantNumber'],
  sheet['pantTeamName'],
  sheet['pantLogo'],
]

export default sheet
export { sheetArray }
