import * as _ from '@technically/lodash'

const viewAnglesRaw = {
  front: {
    azimuthal: 10,
    polar: -4,
    zoom: 0,
    label: 'Front',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
  },
  left: {
    azimuthal: 90,
    polar: -10,
    zoom: 0,
    label: 'Left',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
  },
  back: {
    azimuthal: 170,
    polar: -8,
    zoom: 0,
    label: 'Back',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
  },
  right: {
    azimuthal: -90,
    polar: -10,
    zoom: 0,
    label: 'Right',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
  },
  designIcon: {
    azimuthal: 37,
    polar: -7,
    zoom: 0,
    exposeForUI: false,
    exposeForRecipePreviewImages: false,
  },
  designIcon2: {
    azimuthal: -37,
    polar: -7,
    zoom: 0,
    exposeForUI: false,
    exposeForRecipePreviewImages: false,
  },
}

const defaultViewAngleId = 'front'

// Convert degrees to radians with base angle
const convertAngle = (deg) => Math.PI / 2 + (deg / 180) * Math.PI

const viewAngles = _.mapValues(viewAnglesRaw, (angle) => {
  if (!angle) {
    return undefined
  }

  const { azimuthal, polar, ...rest } = angle

  return {
    ...rest,
    azimuthal: convertAngle(azimuthal),
    polar: convertAngle(polar),
  }
})

export default viewAngles
export { defaultViewAngleId }
