import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { withProps } from 'recompose'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import handleErrors from '../../../platform/client/handleErrors'
import getAsset from '~p/getAsset'
import * as controlTreeDebugUtils from '~p/client/control-tree/debugUtils'
import Application from '~p/client/containers/Application'
import ErrorBoundary from '~p/client/components/ErrorBoundary'
import * as controlLib from '~p/client/control-tree'
import configureRouter from '~p/client/configureRouter'
import sportId from '~p/sportId'
import {
  openMenu,
  togglePreviewMinimization,
  ensurePreviewIntroduced,
  setOriginValues,
  loadRecipe,
  saveRecipe,
  modifyRecipe,
  toggleShare,
  setPreviewMinimization,
} from '~p/client/common/actions'
import {
  isRecipeFinalizedSelector,
  recipeIdSelector,
  isSkuSelector,
  filteredGarmentSelector,
  garmentFilterSelector,
  garmentGroupSelector,
  createMenuSelector,
  getPreviewUrlsSelector,
  isAppLoadingSelector,
} from '~p/client/common/selectors'
import Layout from '~mizuno/client/components/Layout'
import NavigationTab from '~mizuno/client/components/atoms/NavigationTab'
import Roster from '~mizuno/client/components/atoms/Roster'
import RosterRow from '~mizuno/client/components/atoms/RosterRow'
import RosterAdd from '~mizuno/client/components/atoms/RosterAdd'
import Button from '~mizuno/client/components/atoms/Button'
import FinalizeRow from '~mizuno/client/components/atoms/FinalizeRow'
import ContentBody from '~mizuno/client/components/atoms/ContentBody'
import ContentHead from '~mizuno/client/components/atoms/ContentHead'
import ContentLabel from '~mizuno/client/components/atoms/ContentLabel'
import ProductSet from '~mizuno/client/components/molecules/ProductSet'
import ProductTile from '~mizuno/client/components/molecules/ProductTile'
import Navigation from '~mizuno/client/components/organisms/Navigation'
import _ProductTitle from '~mizuno/client/components/organisms/ProductTitle'
import StaticSection from '~mizuno/client/components/organisms/StaticSection'
import FinalizeSection from '~mizuno/client/components/organisms/FinalizeSection'
import FinalizeBlock from '~mizuno/client/components/organisms/FinalizeBlock'
import { cn, mods } from '~p/client/components/utils'
import SocialIcons from '~mizuno/client/containers/SocialIcons'
import ResetRecipe from '~mizuno/client/containers/ResetRecipe'
import TileSelect from '~mizuno/client/components/TileSelect'
import { connectOrders } from '~p/client/component-enhancers'
import PreviewImages from '~p/client/components/PreviewImages'
import '~mizuno/client/components/Root.css'

import store from './store'
import { Render3d, Render3dEngine } from './render3d'
import { setZoomMode } from './render3d/actions'
import { setGarmentFilter } from './actions'
import controlTree from './controlTree'
import sheets from '../common/sheets'
import get3dConfig from '../render3d/get3dConfig'
import viewAngles, { defaultViewAngleId } from '../viewAngles'
import ContactForm from './containers/ContactForm'
import _Finalize from './containers/Finalize'
import _ControlMenu from './containers/ControlMenu'
import ToggleSection from './containers/ToggleSection'
import ToggleBlock from './containers/ToggleBlock'
import * as productControls from './containers/controls'

handleErrors(store)

// For debugging.
window.store = store

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
_.forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

const ua = navigator.userAgent.toLowerCase()

// User-agent check is special case for mizuno-uniforms which fixes some IE styling issue.
if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
  viewportUnitsBuggyfill.init()
}

const withCT = withProps({ controlTree })

const {
  TextInputControl,
  ColorSelectControl,
  FontSelectControl,
  FontLayoutSelectControl,
  FontTailSelectControl,
  PatternSelectControl,
  FabricSelectControl,
  GraphicDesignSelectControl,
  FileUploadControl,
  ImageUploadControl,
  SelectInputControl,
  TextareaInputControl,
} = fp.mapValues(withCT, productControls)
const ControlMenu = withCT(_ControlMenu)

const Fragment = withCT(controlLib.Fragment)
const ControlGroup = withCT(controlLib.ControlGroup)
const repeatedControl = (path, component) =>
  withCT(controlLib.repeatedControl(path, component))

const ProductTitle = connectOrders(controlTree, _ProductTitle)
const Finalize = connectOrders(controlTree, _Finalize)

const routes = [
  [
    '/design/:recipeId(/:status)',
    ({ recipeId }) => /[a-z0-9]+/.test(recipeId) && recipeId.length >= 3,
  ],
  '/sku/:garment',
]

const { initialMatches } = configureRouter(controlTree, store, routes)

const initialState = store.getState()

const sceneDefaults = get3dConfig(
  controlTree.getExpandedRecipe(initialState),
).defaults
const renderer = new Render3dEngine({ sceneDefaults, viewAngles })

if (initialMatches && initialMatches.recipeId) {
  store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
} else if (initialMatches && isSkuSelector(initialState)) {
  const { garment } = initialMatches
  store.dispatch(controlTree.setValues({ garment }))
  store.dispatch(setOriginValues(controlTree))
} else {
  store.dispatch(setOriginValues(controlTree))
}

store.dispatch(
  setGarmentFilter(
    controlTree.getExpandedRecipe(initialState).garment.garmentGroupId,
  ),
)

window.addEventListener('message', (ev) => {
  if (fp.has('type', ev.data)) {
    store.dispatch(ev.data)
  }
})

{
  const mql = window.matchMedia('(min-width: 1000px)')
  if (mql.matches) store.dispatch(setPreviewMinimization(false))
  mql.addListener((x) => store.dispatch(setPreviewMinimization(!x.matches)))
}

const GarmentFilter = connect(
  (state) => ({
    garmentGroups: garmentGroupSelector(state),
    garmentFilter: garmentFilterSelector(state),
  }),
  { setGarmentFilter },
)((props) => (
  <TileSelect
    type="TEXT"
    options={props.garmentGroups}
    value={props.garmentFilter}
    change={props.setGarmentFilter}
  />
))

const FillControl = connect((state, { pathPrefix }) => {
  const nodes = controlTree.getNodes(state)

  const graphicNode = nodes[`${pathPrefix}.graphicTexture`]
  const colorNode = nodes[`${pathPrefix}.color1`]
  const isSolidFill = graphicNode.value === 'solid'
  const secondaryLabel =
    isSolidFill ? colorNode.optionName : graphicNode.optionName

  return { secondaryLabel }
})(({ link, pathPrefix, primaryLabel, secondaryLabel }) => (
  <ControlMenu
    link={link}
    primaryLabel={primaryLabel}
    secondaryLabel={secondaryLabel}
  >
    <PatternSelectControl
      path={`${pathPrefix}.graphicTexture`}
      showLabel={false}
    />
    <ColorSelectControl path={`${pathPrefix}.color1`} showLabel />
    <ColorSelectControl path={`${pathPrefix}.color2`} showLabel />
    <ColorSelectControl path={`${pathPrefix}.color3`} showLabel />
  </ControlMenu>
))

const PipingPositionControl = connect((state, { path }) => {
  const node = controlTree.getNodes(state)[path]

  const optionNames = fp.pipe(
    fp.filter(({ id }) => fp.includes(id, node.value)),
    fp.map('name'),
  )(node.options)
  const secondaryLabel =
    fp.isEmpty(optionNames) ? 'None' : optionNames.join(' & ')

  return { path, secondaryLabel }
})(({ path, secondaryLabel }) => (
  <ControlMenu path={path} secondaryLabel={secondaryLabel} />
))

const FillArea = ({ label, fillAreaId }) => (
  <ControlGroup controlPaths={`fillAreas.${fillAreaId}.*`}>
    <FillControl
      link={`fillAreas.${fillAreaId}`}
      pathPrefix={`fillAreas.${fillAreaId}`}
      primaryLabel={label}
    />
  </ControlGroup>
)

const DesignGraphicsControl = () => (
  <ControlGroup controlPaths="design.*">
    <div className="graphics-control">
      <GraphicDesignSelectControl path="design.graphicDesign" />
    </div>
  </ControlGroup>
)

const Deco = ({ deco, deco: { id } }) => (
  <ControlGroup controlPaths={[`decos.${id}.position`]}>
    <ToggleSection
      sectionId={`decos.${id}`}
      childrenPath={`decos.${id}.*`}
      primaryLabel={deco.name}
    >
      {() => (
        <div>
          <ControlMenu path={`decos.${id}.position`} appendNone />
          <ControlMenu path={`decos.${id}.size`} />

          <ControlGroup controlPaths={`decos.${id}.content.text`}>
            <ControlMenu path={`decos.${id}.content.text`} />
            <ControlMenu path={`decos.${id}.content.font`}>
              <FontSelectControl path={`decos.${id}.content.font`} />
            </ControlMenu>
            <FillControl
              link={`decos.${id}.fill`}
              pathPrefix={`decos.${id}.content.fill`}
              primaryLabel="Fill:"
            />
            <ControlMenu path={`decos.${id}.content.outlineColor1`}>
              <ColorSelectControl path={`decos.${id}.content.outlineColor1`} />
            </ControlMenu>
            <ControlMenu path={`decos.${id}.content.outlineColor2`}>
              <ColorSelectControl path={`decos.${id}.content.outlineColor2`} />
            </ControlMenu>
          </ControlGroup>

          <ControlGroup controlPaths={`decos.${id}.content.layout`}>
            <ControlMenu path={`decos.${id}.content.layout`}>
              <FontLayoutSelectControl path={`decos.${id}.content.layout`} />
            </ControlMenu>
          </ControlGroup>

          <ControlGroup controlPaths={`decos.${id}.content.tailingAvailable`}>
            <ControlMenu path={`decos.${id}.content.tailEnabled`} />
          </ControlGroup>

          <ControlGroup controlPaths={`decos.${id}.content.tailEnabled`}>
            <ControlMenu path={`decos.${id}.content.tailShape`}>
              <FontTailSelectControl path={`decos.${id}.content.tailShape`} />
            </ControlMenu>
            <ControlMenu path={`decos.${id}.content.tailText`} />
            <ControlMenu path={`decos.${id}.content.tailTextFont`}>
              <FontSelectControl path={`decos.${id}.content.tailTextFont`} />
            </ControlMenu>
            <ControlMenu path={`decos.${id}.content.tailTextColor`}>
              <ColorSelectControl path={`decos.${id}.content.tailTextColor`} />
            </ControlMenu>
          </ControlGroup>

          <ControlGroup controlPaths={`decos.${id}.content.previewImage`}>
            <ControlMenu path={`decos.${id}.content.previewImage`}>
              <ImageUploadControl
                path={`decos.${id}.content.previewImage`}
                label={null}
                subline="PNG, JPEG or GIF file for immediate visualization"
              />
            </ControlMenu>
            <ControlMenu path={`decos.${id}.content.factoryFile`}>
              <FileUploadControl
                path={`decos.${id}.content.factoryFile`}
                label={null}
                subline="PDF, EPS or AI file for production needs"
              />
            </ControlMenu>
          </ControlGroup>
        </div>
      )}
    </ToggleSection>
  </ControlGroup>
)

const RosterControl = repeatedControl(
  'details.roster',
  ({ controlIds, add, remove }) => (
    <ControlMenu
      link="details.roster"
      primaryLabel="Team Roster:"
      secondaryLabel={null}
    >
      <Roster>
        <RosterRow>
          <span>Size</span>
          <span>QTY</span>
          <span>#</span>
          <span>Name</span>
          <span>&nbsp;</span>
        </RosterRow>

        {_.map(controlIds, (id) => (
          <RosterRow key={id}>
            <SelectInputControl
              path={`details.roster.${id}.size`}
              classMods={['fullBorder']}
            />
            <TextInputControl
              path={`details.roster.${id}.quantity`}
              classMods={['fullBorder']}
            />
            <TextInputControl
              path={`details.roster.${id}.number`}
              classMods={['fullBorder']}
            />
            <TextInputControl
              path={`details.roster.${id}.name`}
              classMods={['fullBorder']}
            />
            <Button classMods={['action', 'remove']} onClick={() => remove(id)}>
              X
            </Button>
          </RosterRow>
        ))}
      </Roster>

      <RosterAdd>
        <Button classMods={['action', 'add']} onClick={() => add()}>
          +
        </Button>
      </RosterAdd>
    </ControlMenu>
  ),
)

class FinalizeContent extends React.Component {
  rosterTable() {
    const { rosterIds, expandedRecipe } = this.props

    if (!rosterIds.length) {
      return null
    }

    const items = _.map(rosterIds, (rosterId) => ({
      id: rosterId,
      size: expandedRecipe[`details.roster.${rosterId}.size`],
      quantity: expandedRecipe[`details.roster.${rosterId}.quantity`],
      number: expandedRecipe[`details.roster.${rosterId}.number`],
      name: expandedRecipe[`details.roster.${rosterId}.name`],
    }))

    const summary = _.reduce(
      items,
      (r, { size, quantity }) =>
        _.update(
          r,
          size.id,
          (quantityBefore) => (quantityBefore || 0) + +quantity,
        ),
      {},
    )
    const total = _.sum(_.values(summary))

    return (
      <div>
        <div className={cn(['block', mods(['finalize'])])}>
          <ContentHead classMods={['block', 'finalize']}>
            <ContentLabel classMods={['finalize']}>
              <strong>Team Roster</strong>
            </ContentLabel>
          </ContentHead>
          <ContentBody classMods={['block', 'finalize']}>
            <table className="roster-table">
              <thead>
                <tr>
                  <th>Size</th>
                  <th>Quantity</th>
                  <th>Number</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {_.map(items, (item) => (
                  <tr key={item.id}>
                    <td>{this.rowValue(item.size)}</td>
                    <td>{this.rowValue(item.quantity)}</td>
                    <td>{this.rowValue(item.number)}</td>
                    <td>{this.rowValue(item.name)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContentBody>
        </div>

        <div className={cn(['block', mods(['finalize'])])}>
          <ContentHead classMods={['block', 'finalize']}>
            <ContentLabel classMods={['finalize']}>
              <strong>Size Summary</strong>
            </ContentLabel>
          </ContentHead>
          <ContentBody classMods={['block', 'finalize']}>
            <table className="roster-table">
              <thead>
                <tr>
                  <th>Size</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {_.map(summary, (quantity, size) => (
                  <tr key={size}>
                    <td>{this.rowValue(size)}</td>
                    <td>{this.rowValue(quantity)}</td>
                  </tr>
                ))}

                <tr className="roster-total">
                  <td>Total</td>
                  <td>{total}</td>
                </tr>
              </tbody>
            </table>
          </ContentBody>
        </div>
      </div>
    )
  }

  fillArea(pathPrefix, label) {
    const control = this.props.expandedRecipe[`${pathPrefix}.graphicTexture`]
    if (!control) {
      return null
    }

    return (
      <FinalizeBlock key={pathPrefix} primaryLabel={label}>
        {control.id !== 'solid' ?
          this.row(`${pathPrefix}.graphicTexture`, 'Graphic Texture')
        : null}

        {this.row(`${pathPrefix}.color1`, 'Color 1')}
        {this.row(`${pathPrefix}.color2`, 'Color 2')}
        {this.row(`${pathPrefix}.color3`, 'Color 3')}
      </FinalizeBlock>
    )
  }

  decoArea(deco) {
    const { id } = deco
    const position = this.row(`decos.${id}.position`, 'Position')

    if (position == null) {
      return null
    }

    const previewImage =
      this.props.expandedRecipe[`decos.${id}.content.previewImage`]
    const factoryFile =
      this.props.expandedRecipe[`decos.${id}.content.factoryFile`]

    return (
      <FinalizeBlock key={id} primaryLabel={deco.name}>
        {position}
        {this.row(`decos.${id}.size`, 'Size')}
        {this.row(`decos.${id}.content.text`, 'Text')}
        {this.row(`decos.${id}.content.font`, 'Font')}
        {this.row(`decos.${id}.content.fill.graphicTexture`, 'Graphic Texture')}
        {this.row(`decos.${id}.content.fill.color1`, 'Color 1')}
        {this.row(`decos.${id}.content.fill.color2`, 'Color 2')}
        {this.row(`decos.${id}.content.fill.color3`, 'Color 3')}
        {this.row(`decos.${id}.content.outlineColor1`, 'Outline Color 1')}
        {this.row(`decos.${id}.content.outlineColor2`, 'Outline Color 2')}
        {this.row(`decos.${id}.content.layout`, 'Layout')}
        {this.row(`decos.${id}.content.tailText`, 'Tailing Text')}
        {this.row(`decos.${id}.content.tailTextFont`, 'Tailing Font')}
        {this.row(`decos.${id}.content.tailShape`, 'Tailing Option')}
        {this.row(`decos.${id}.content.tailTextColor`, 'Tailing Color')}
        {previewImage ?
          <FinalizeRow
            label="Preview Image"
            value={previewImage}
            id={`decos.${id}.content.previewImage`}
          />
        : null}
        {factoryFile ?
          <FinalizeRow
            label="Hi-Res File"
            value={factoryFile}
            id={`decos.${id}.content.factoryFile`}
          />
        : null}
      </FinalizeBlock>
    )
  }

  piping() {
    const positions = this.row('piping.positions', 'Positions')

    if (positions == null) {
      return null
    }

    return (
      <FinalizeBlock primaryLabel="Piping">
        {positions}
        {this.row('piping.content.color1', 'Color 1')}
        {this.row('piping.content.color2', 'Color 2')}
        {this.row('piping.content.color3', 'Color 3')}
      </FinalizeBlock>
    )
  }

  specialInstructions() {
    const text = this.row('details.specialInstructions.text', 'Text')

    if (text == null) {
      return null
    }

    return (
      <FinalizeBlock primaryLabel="Special Instructions">{text}</FinalizeBlock>
    )
  }

  recipeName() {
    const text = this.row('details.recipeName.text', 'Text')

    if (text == null) {
      return null
    }

    return <FinalizeBlock primaryLabel="Design Name">{text}</FinalizeBlock>
  }

  rowValue(control) {
    if (fp.isArray(control)) {
      return fp.join(
        ', ',
        fp.map((c) => this.rowValue(c), control),
      )
    }

    if (control.name) {
      return control.name
    }

    return control
  }

  row(path, name) {
    const control = this.props.expandedRecipe[path]

    const isValueEmpty =
      control == null ||
      (fp.isArray(control) && control.length < 1) ||
      (fp.isString(control) && control === '')

    return isValueEmpty ? null : (
        <FinalizeRow label={name} value={this.rowValue(control)} />
      )
  }

  render() {
    const { expandedRecipe, isShareVisible, rosterIds } = this.props

    const hasContentInRosterSection =
      rosterIds.length ||
      expandedRecipe['details.specialInstructions.text'] ||
      expandedRecipe['details.recipeName.text']

    const isDesignSolid = expandedRecipe['design.graphicDesign'].id === 'solid'

    return (
      <div>
        {isShareVisible ?
          <ContentBody
            classMods={['block', 'largeScreenHide']}
            classStates={['on']}
          >
            <SocialIcons classMods={['smallScreen']} showCopy />
          </ContentBody>
        : null}

        <ContactForm />

        <FinalizeSection primaryLabel="Product">
          <FinalizeBlock primaryLabel="General">
            {this.row('garmentType', 'Garment Type')}
            {this.row('garment', 'Garment')}
            {this.row('fabric', 'Fabric')}
          </FinalizeBlock>
        </FinalizeSection>

        <FinalizeSection primaryLabel="Design">
          {!isDesignSolid || expandedRecipe['design.accent1'] ?
            <FinalizeBlock primaryLabel="Graphics">
              {!isDesignSolid ?
                this.row('design.graphicDesign', 'Design')
              : null}

              {this.row('design.accent1', 'Accent Color 1')}
              {this.row('design.accent2', 'Accent Color 2')}
              {this.row('design.accent3', 'Accent Color 3')}
            </FinalizeBlock>
          : null}

          {this.fillArea(`fillAreas.body`, 'Body')}
          {this.fillArea(`fillAreas.sleeve`, 'Sleeve')}
          {this.fillArea(`fillAreas.collar`, 'Collar')}
          {this.fillArea(`fillAreas.pocket`, 'Pocket')}
          {this.fillArea(`fillAreas.loop`, 'Loop')}
          {this.fillArea(`fillAreas.mizunoLogo`, 'Mizuno Logo')}
          {this.fillArea(`fillAreas.panel`, 'Panel')}
        </FinalizeSection>

        <FinalizeSection primaryLabel="Decos">
          {_.map(sheets.decos, (deco) => this.decoArea(deco))}
          {this.piping()}
        </FinalizeSection>

        {hasContentInRosterSection ?
          <FinalizeSection primaryLabel="Roster">
            {this.rosterTable()}

            {this.specialInstructions()}
            {this.recipeName()}
          </FinalizeSection>
        : null}
      </div>
    )
  }
}

const menuSelector = createMenuSelector(controlTree)

const Nav = connect(
  (state) => ({
    menu: menuSelector(state),
  }),
  (dispatch) => ({
    openMenuConnected: (menu) => dispatch(openMenu(controlTree, menu)),
  }),
)(({ menu, openMenuConnected }) => (
  <div>
    <Navigation>
      <NavigationTab
        classStates={menu === 'product' ? ['active'] : []}
        onClick={() => {
          openMenuConnected('product')
        }}
        data-gtm="tab-product"
      >
        Product
      </NavigationTab>
      <NavigationTab
        classStates={menu === 'design' ? ['active'] : []}
        onClick={() => {
          openMenuConnected('design')
        }}
        data-gtm="tab-design"
      >
        Design
      </NavigationTab>
      <NavigationTab
        classStates={menu === 'decorate' ? ['active'] : []}
        onClick={() => {
          openMenuConnected('decorate')
        }}
        data-gtm="tab-decorate"
      >
        Decorate
      </NavigationTab>
      <NavigationTab
        classStates={menu === 'details' ? ['active'] : []}
        onClick={() => {
          openMenuConnected('details')
        }}
        data-gtm="tab-details"
      >
        Roster
      </NavigationTab>
    </Navigation>
  </div>
))

const Sidebar = () => (
  <div id="sidebar">
    <Nav />

    <Fragment forRoute="product">
      {() => (
        <StaticSection primaryLabel="Filters">
          <ToggleBlock blockId="garment" primaryLabel="Garment Group:">
            <GarmentFilter />
          </ToggleBlock>
        </StaticSection>
      )}
    </Fragment>

    <Fragment forRoute="design" controlPaths={['design.*', 'fillAreas.*']}>
      {() => (
        <div>
          <ControlGroup controlPaths="design.*">
            <ToggleSection
              sectionId="design"
              childrenPath="design.*"
              primaryLabel="Design"
            >
              {() => <DesignGraphicsControl />}
            </ToggleSection>
          </ControlGroup>

          <ControlGroup controlPaths={['fillAreas.*', 'design.color1']}>
            <ToggleSection
              sectionId="fillAreas"
              childrenPath="fillAreas.*"
              primaryLabel="Colors"
            >
              {() => (
                <div>
                  <FillArea fillAreaId="body" label="Body:" />
                  <FillArea fillAreaId="sleeve" label="Sleeve:" />
                  <FillArea fillAreaId="collar" label="Collar:" />
                  <FillArea fillAreaId="pocket" label="Pocket:" />
                  <FillArea fillAreaId="loop" label="Loop:" />
                  <FillArea fillAreaId="mizunoLogo" label="Mizuno Logo:" />
                  <FillArea fillAreaId="panel" label="Panel:" />

                  <ControlMenu path="design.accent1">
                    <ColorSelectControl path="design.accent1" />
                  </ControlMenu>
                  <ControlMenu path="design.accent2">
                    <ColorSelectControl path="design.accent2" />
                  </ControlMenu>
                  <ControlMenu path="design.accent3">
                    <ColorSelectControl path="design.accent3" />
                  </ControlMenu>
                </div>
              )}
            </ToggleSection>
          </ControlGroup>

          <FabricSelectControl path="fabric" />

          <ResetRecipe />
        </div>
      )}
    </Fragment>

    <Fragment
      forRoute="decorate"
      controlPaths={['decos.*.position', 'piping.positions']}
    >
      {() => (
        <div>
          <ControlGroup controlPaths="decos.*.position">
            {_.map(sheets.decos, (deco) => (
              <Deco key={deco.id} deco={deco} />
            ))}
          </ControlGroup>

          <ToggleSection
            sectionId="piping"
            childrenPath="piping.*"
            primaryLabel="Piping"
          >
            {() => (
              <div>
                <PipingPositionControl path="piping.positions" />
                <ControlMenu path="piping.content.color1">
                  <ColorSelectControl path="piping.content.color1" />
                </ControlMenu>
                <ControlMenu path="piping.content.color2">
                  <ColorSelectControl path="piping.content.color2" />
                </ControlMenu>
                <ControlMenu path="piping.content.color3">
                  <ColorSelectControl path="piping.content.color3" />
                </ControlMenu>
              </div>
            )}
          </ToggleSection>

          <ResetRecipe />
        </div>
      )}
    </Fragment>

    <Fragment forRoute="details">
      {() => (
        <div>
          <RosterControl />

          <ControlMenu
            path="details.specialInstructions.text"
            secondaryLabel={null}
          >
            <TextareaInputControl path="details.specialInstructions.text" />
          </ControlMenu>

          <ControlMenu path="details.recipeName.text" secondaryLabel={null} />

          <ResetRecipe />
        </div>
      )}
    </Fragment>
  </div>
)

const Root = connect(
  (state) => {
    const nodes = controlTree.getNodes(state)
    const garmentNode = nodes.garment

    const garmentName = garmentNode.optionName

    return {
      isAppLoading: isAppLoadingSelector(state),
      isRecipeFinalized: isRecipeFinalizedSelector(state),
      isPreviewMinimized: state.isPreviewMinimized,
      previewUrls: getPreviewUrlsSelector(
        viewAngles,
        defaultViewAngleId,
      )(state),
      menu: menuSelector(state),
      recipeId: recipeIdSelector(state),
      products: filteredGarmentSelector(state),
      isShareVisible: state.isShareVisible,
      expandedRecipe: controlTree.getExpandedRecipe(state),
      rosterIds: controlTree.getRepeatedNodes(state, 'details.roster'),
      garmentName,
    }
  },
  (dispatch) => ({
    openMenu: (menu) => dispatch(openMenu(controlTree, menu)),
    saveRecipe: () => dispatch(saveRecipe(controlTree)),
    modifyRecipe: () => dispatch(modifyRecipe()),
    togglePreviewMinimization: () => dispatch(togglePreviewMinimization()),
    toggleShare: () => dispatch(toggleShare()),
    setZoomMode: (x) => dispatch(setZoomMode(x)),
    changeGarment: (id) => {
      dispatch(ensurePreviewIntroduced())
      dispatch(controlTree.change('garment', id))
      dispatch(openMenu(controlTree, 'design'))
    },
  }),
)((props) => {
  const isProductScreen = props.menu === 'product' && !props.isRecipeFinalized
  const isFinalizeScreen = props.isRecipeFinalized

  const garmentTypeId = props.expandedRecipe.garmentType.id

  return (
    <Layout
      {...props}
      isProductScreen={isProductScreen}
      isFinalizeScreen={isFinalizeScreen}
    >
      <ErrorBoundary>
        <ProductTitle
          previewState={props.isPreviewMinimized ? 'off' : 'on'}
          title={
            props.expandedRecipe['details.recipeName.text'] ||
            `My custom ${garmentTypeId}`
          }
          name={props.garmentName}
          saveButtonLabel={`Save my ${garmentTypeId}`}
          onSave={() => props.saveRecipe()}
          onShare={() => {
            window.scrollTo(0, 0)
            props.toggleShare()
          }}
          onPreviewToggle={() => {
            props.togglePreviewMinimization()
            props.setZoomMode('auto')
          }}
        />
      </ErrorBoundary>

      <div className="viewer">
        <ErrorBoundary>
          <div className="preview">
            <Render3d
              engine={renderer}
              get3dConfig={get3dConfig}
              viewAngles={viewAngles}
              controlTree={controlTree}
              isHidden={isProductScreen || isFinalizeScreen}
            />

            {isFinalizeScreen && props.isPreviewMinimized && (
              <img
                className="preview-image-corner"
                src={_.values(props.previewUrls)[0]}
              />
            )}

            {isFinalizeScreen && !props.isPreviewMinimized && (
              <div className="preview-images-outer">
                {!window.serverConfig.hideSocial && (
                  <SocialIcons
                    classMods={['largeScreen']}
                    downloadUrl={props.previewUrls[defaultViewAngleId]}
                  />
                )}

                <PreviewImages previewUrls={props.previewUrls} />
              </div>
            )}
          </div>
        </ErrorBoundary>

        <ErrorBoundary>
          <div className="productSelect">
            <ProductSet>
              {_.map(props.products, (garment) => (
                <ProductTile
                  key={garment.id}
                  name={garment.name}
                  onClick={() => props.changeGarment(garment.id)}
                  imageUrl={getAsset(
                    `${sportId}/icons/${garment.garmentTypeId}/${garment.silhouetteId}-${garment.variantId}.png`,
                  )}
                  buttonText={`Build this ${garment.garmentTypeId}`}
                />
              ))}
            </ProductSet>
          </div>
        </ErrorBoundary>
      </div>

      <ErrorBoundary>
        <div className="sidebar">
          <div className="sidebar-body">
            <Sidebar />
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <Finalize
          controlTree={controlTree}
          onSave={props.saveRecipe}
          resetMenu="design"
          saveButtonLabel={`Save my ${garmentTypeId}`}
          resumeButtonLabel={`Edit ${garmentTypeId}`}
        >
          {isFinalizeScreen ?
            <FinalizeContent
              expandedRecipe={props.expandedRecipe}
              isShareVisible={props.isShareVisible}
              rosterIds={props.rosterIds}
            />
          : null}
        </Finalize>
      </ErrorBoundary>
    </Layout>
  )
})

function onReady() {
  createRoot(document.getElementById('root')).render(
    <Application store={store}>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </Application>,
  )
}

document.addEventListener('DOMContentLoaded', onReady)
