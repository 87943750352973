/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "fullButton": {
    "name": "Full Button",
    "id": "fullButton"
  },
  "2button": {
    "name": "2 Button",
    "id": "2button"
  },
  "vNeck": {
    "name": "V-Neck",
    "id": "vNeck"
  },
  "belted": {
    "name": "Belted",
    "id": "belted"
  },
  "unbelted": {
    "name": "Unbelted",
    "id": "unbelted"
  },
  "crewPullover": {
    "name": "Crew Pullover",
    "id": "crewPullover"
  },
  "fullLength": {
    "name": "Full Length",
    "id": "fullLength"
  },
  "elasticBottom": {
    "name": "Elastic Bottom",
    "id": "elasticBottom"
  },
  "shortPant": {
    "name": "Short Pant",
    "id": "shortPant"
  },
  "female": {
    "name": "Female",
    "id": "female"
  },
  "male": {
    "name": "Male",
    "id": "male"
  },
  "femaleReplica": {
    "name": "Female National replica",
    "id": "femaleReplica"
  },
  "maleReplica": {
    "name": "Male National replica",
    "id": "maleReplica"
  }
}

const sheetArray = [
  sheet['fullButton'],
  sheet['2button'],
  sheet['vNeck'],
  sheet['belted'],
  sheet['unbelted'],
  sheet['crewPullover'],
  sheet['fullLength'],
  sheet['elasticBottom'],
  sheet['shortPant'],
  sheet['female'],
  sheet['male'],
  sheet['femaleReplica'],
  sheet['maleReplica'],
]

export default sheet
export { sheetArray }
