/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "freshman": {
    "name": "Freshman",
    "id": "freshman",
    "asset": {
      "fillId": "Freshman",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1000,
      "outline1": 62.5,
      "outline2": 125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "rockwell": {
    "name": "Rockwell",
    "id": "rockwell",
    "asset": {
      "fillId": "Rockwell",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1000,
      "outline1": 62.5,
      "outline2": 125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "hobo": {
    "name": "Hobo",
    "id": "hobo",
    "asset": {
      "fillId": "Hobo",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1000,
      "outline1": 62.5,
      "outline2": 125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "newAthletic": {
    "name": "New Athletic",
    "id": "newAthletic",
    "asset": {
      "fillId": "NewAthletic",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1000,
      "outline1": 62.5,
      "outline2": 125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "proBlock": {
    "name": "Pro Block",
    "id": "proBlock",
    "asset": {
      "fillId": "ProBlock",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 800,
      "outline1": 50,
      "outline2": 100,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "varsity": {
    "name": "Varsity",
    "id": "varsity",
    "asset": {
      "fillId": "Varsity",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 750,
      "outline1": 46.875,
      "outline2": 93.75,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "tiffany": {
    "name": "Tiffany",
    "id": "tiffany",
    "asset": {
      "fillId": "Tiffany",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 750,
      "outline1": 46.875,
      "outline2": 93.75,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "pirate": {
    "name": "Pirate",
    "id": "pirate",
    "asset": {
      "fillId": "Pirate",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1434,
      "outline1": 89.625,
      "outline2": 179.25,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "oldEnglish": {
    "name": "Old English",
    "id": "oldEnglish",
    "asset": {
      "fillId": "OldEnglish",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": true,
      "capHeight": 750,
      "outline1": 46.875,
      "outline2": 93.75,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": false
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": true,
      "vol-number": true
    }
  },
  "script": {
    "name": "Script",
    "id": "script",
    "asset": {
      "fillId": "Script",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": true,
      "capHeight": 750,
      "outline1": 46.875,
      "outline2": 93.75,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": false
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "all"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": false,
      "vol-all": true,
      "vol-number": false
    }
  },
  "brush": {
    "name": "Brush",
    "id": "brush",
    "asset": {
      "fillId": "Brush",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": true,
      "capHeight": 683,
      "outline1": 42.6875,
      "outline2": 85.375,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": false
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "all"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": false,
      "vol-all": true,
      "vol-number": true
    }
  },
  "hardSport": {
    "name": "Hard Sports",
    "id": "hardSport",
    "asset": {
      "fillId": "HardSport",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 1316,
      "outline1": 82.25,
      "outline2": 164.5,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": false,
      "vol-number": false
    }
  },
  "airAmericana": {
    "name": "Air Americana",
    "id": "airAmericana",
    "asset": {
      "fillId": "AirAmericana",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 801,
      "outline1": 50.0625,
      "outline2": 100.125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": false,
      "vol-number": false
    }
  },
  "airStrike": {
    "name": "Air Strike",
    "id": "airStrike",
    "asset": {
      "fillId": "AirStrike",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 530,
      "outline1": 33.125,
      "outline2": 66.25,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": false,
      "vol-number": false
    }
  },
  "chickweed": {
    "name": "Chickweed",
    "id": "chickweed",
    "asset": {
      "fillId": "Chickweed",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 753,
      "outline1": 47.0625,
      "outline2": 94.125,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": false,
      "vol-number": false
    }
  },
  "streetCredit": {
    "name": "Street Credit",
    "id": "streetCredit",
    "asset": {
      "fillId": "StreetCredit",
      "outline1Id": null,
      "outline2Id": null,
      "lowercase": false,
      "capHeight": 667,
      "outline1": 41.6875,
      "outline2": 83.375,
      "forceOutline1": false,
      "spacing": 0,
      "outlineSpacing": true
    },
    "limitations": {
      "maxOutlineCount": 2,
      "letteringTailSubsetId": "sweep"
    },
    "subsets": {
      "bbsb-all": true,
      "bbsb-number": true,
      "vol-all": false,
      "vol-number": false
    }
  }
}

const sheetArray = [
  sheet['freshman'],
  sheet['rockwell'],
  sheet['hobo'],
  sheet['newAthletic'],
  sheet['proBlock'],
  sheet['varsity'],
  sheet['tiffany'],
  sheet['pirate'],
  sheet['oldEnglish'],
  sheet['script'],
  sheet['brush'],
  sheet['hardSport'],
  sheet['airAmericana'],
  sheet['airStrike'],
  sheet['chickweed'],
  sheet['streetCredit'],
]

export default sheet
export { sheetArray }
