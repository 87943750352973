/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "jersey": {
    "name": "Jersey",
    "id": "jersey"
  },
  "womensJersey": {
    "name": "Women's Jersey",
    "id": "womensJersey"
  },
  "mensJersey": {
    "name": "Men's Jersey",
    "id": "mensJersey"
  },
  "replicaJersey": {
    "name": "Replica Jersey",
    "id": "replicaJersey"
  },
  "pant": {
    "name": "Pant",
    "id": "pant"
  }
}

const sheetArray = [
  sheet['jersey'],
  sheet['womensJersey'],
  sheet['mensJersey'],
  sheet['replicaJersey'],
  sheet['pant'],
]

export default sheet
export { sheetArray }
