/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "3": {
    "name": "3 inch",
    "id": "3"
  },
  "4": {
    "name": "4 inch",
    "id": "4"
  },
  "6": {
    "name": "6 inch",
    "id": "6"
  },
  "8": {
    "name": "8 inch",
    "id": "8"
  }
}

const sheetArray = [
  sheet['3'],
  sheet['4'],
  sheet['6'],
  sheet['8'],
]

export default sheet
export { sheetArray }
