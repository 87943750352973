/* eslint-disable */

// This is generated by a script, see platform/common/importer




const sheet = {
  "female-capSleeve": {
    "sportId": "volleyball",
    "garmentGroupId": "womensJersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "female",
    "variantId": "capSleeve",
    "id": "female-capSleeve",
    "name": "Cap Sleeve",
    "asset": {
      "modelId": "female-capSleeve",
      "textureId": "female-capSleeve"
    },
    "limitations": {
      "fabricSubsetId": "volleyballJersey",
      "designSubsetId": "volleyballFemale",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "volleyballJersey",
      "pipingSubsetId": null,
      "roasterSizeSubsetId": "volleyball"
    }
  },
  "female-shortSleeve": {
    "sportId": "volleyball",
    "garmentGroupId": "womensJersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "female",
    "variantId": "shortSleeve",
    "id": "female-shortSleeve",
    "name": "Short Sleeve",
    "asset": {
      "modelId": "female-shortSleeve",
      "textureId": "female-shortSleeve"
    },
    "limitations": {
      "fabricSubsetId": "volleyballJersey",
      "designSubsetId": "volleyballFemale",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "volleyballJersey",
      "pipingSubsetId": null,
      "roasterSizeSubsetId": "volleyball"
    }
  },
  "female-longSleeve": {
    "sportId": "volleyball",
    "garmentGroupId": "womensJersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "female",
    "variantId": "longSleeve",
    "id": "female-longSleeve",
    "name": "Long Sleeve",
    "asset": {
      "modelId": "female-longSleeve",
      "textureId": "female-longSleeve"
    },
    "limitations": {
      "fabricSubsetId": "volleyballJersey",
      "designSubsetId": "volleyballFemale",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "volleyballJersey",
      "pipingSubsetId": null,
      "roasterSizeSubsetId": "volleyball"
    }
  },
  "male-shortSleeve": {
    "sportId": "volleyball",
    "garmentGroupId": "mensJersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "male",
    "variantId": "shortSleeve",
    "id": "male-shortSleeve",
    "name": "Short Sleeve",
    "asset": {
      "modelId": "male-shortSleeve",
      "textureId": "male-shortSleeve"
    },
    "limitations": {
      "fabricSubsetId": "volleyballJersey",
      "designSubsetId": "volleyballMale",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "regularJersey",
      "decoSubsetId": "volleyballJersey",
      "pipingSubsetId": null,
      "roasterSizeSubsetId": "volleyball"
    }
  },
  "male-sleeveless": {
    "sportId": "volleyball",
    "garmentGroupId": "mensJersey",
    "garmentTypeId": "jersey",
    "silhouetteId": "male",
    "variantId": "sleeveless",
    "id": "male-sleeveless",
    "name": "Sleeveless",
    "asset": {
      "modelId": "male-sleeveless",
      "textureId": "male-sleeveless"
    },
    "limitations": {
      "fabricSubsetId": "volleyballJersey",
      "designSubsetId": "volleyballMale",
      "designIsOptional": true,
      "graphicTextureIsAvailable": {
        "body": true,
        "sleeve": true,
        "collar": true,
        "pocket": true,
        "loop": true,
        "panel": false,
        "mizunoLogo": false
      },
      "colorSubsetId": "all",
      "fillAreaSubsetId": "sleevelessJersey",
      "decoSubsetId": "volleyballJersey",
      "pipingSubsetId": null,
      "roasterSizeSubsetId": "volleyball"
    }
  }
}

const sheetArray = [
  sheet['female-capSleeve'],
  sheet['female-shortSleeve'],
  sheet['female-longSleeve'],
  sheet['male-shortSleeve'],
  sheet['male-sleeveless'],
]

export default sheet
export { sheetArray }
