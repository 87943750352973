/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "frontLogo": {
    "sportPrefix": "vol",
    "id": "frontLogo",
    "uniqueId": "vol-frontLogo",
    "name": "Front Logo",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": true
    },
    "defaults": {
      "enabled": true,
      "position": "leftChest",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "leftChest"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "sleeveLogo": {
    "sportPrefix": "vol",
    "id": "sleeveLogo",
    "uniqueId": "vol-sleeveLogo",
    "name": "Sleeve Logo",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "sleeveLeft",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "sleeveLeft",
        "sleeveRight"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "backLogo": {
    "sportPrefix": "vol",
    "id": "backLogo",
    "uniqueId": "vol-backLogo",
    "name": "Back Logo",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "lowerBack",
      "size": null,
      "text": null,
      "font": null
    },
    "limitations": {
      "contentTypeId": "graphic",
      "letteringFontSubsetId": null,
      "maxCharCount": null,
      "decoPlacementIds": [
        "upperBack",
        "lowerBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "teamName": {
    "sportPrefix": "vol",
    "id": "teamName",
    "uniqueId": "vol-teamName",
    "name": "Team Name",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "upperBack",
      "size": null,
      "text": "Teamname",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "text",
      "letteringFontSubsetId": "vol-all",
      "maxCharCount": null,
      "decoPlacementIds": [
        "leftChest",
        "sleeveLeft",
        "sleeveRight",
        "upperBack",
        "lowerBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "playerName": {
    "sportPrefix": "vol",
    "id": "playerName",
    "uniqueId": "vol-playerName",
    "name": "Player Name",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": false,
      "position": "upperBack",
      "size": null,
      "text": "Player Name",
      "font": "varsity"
    },
    "limitations": {
      "contentTypeId": "text",
      "letteringFontSubsetId": "vol-all",
      "maxCharCount": null,
      "decoPlacementIds": [
        "upperBack"
      ],
      "decoSizeIds": []
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "frontNumber": {
    "sportPrefix": "vol",
    "id": "frontNumber",
    "uniqueId": "vol-frontNumber",
    "name": "Front Number",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "fullFront",
      "size": "6",
      "text": "83",
      "font": "newAthletic"
    },
    "limitations": {
      "contentTypeId": "number",
      "letteringFontSubsetId": "vol-number",
      "maxCharCount": 2,
      "decoPlacementIds": [
        "fullFront"
      ],
      "decoSizeIds": [
        "4",
        "6"
      ]
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  },
  "backNumber": {
    "sportPrefix": "vol",
    "id": "backNumber",
    "uniqueId": "vol-backNumber",
    "name": "Back Number",
    "subsets": {
      "bbsbJersey": false,
      "bbsbSleevelessJersey": false,
      "bbsbPant": false,
      "volleyballJersey": true,
      "volleyballReplicaJersey": false
    },
    "defaults": {
      "enabled": true,
      "position": "fullBack",
      "size": "8",
      "text": "83",
      "font": "newAthletic"
    },
    "limitations": {
      "contentTypeId": "number",
      "letteringFontSubsetId": "vol-number",
      "maxCharCount": 2,
      "decoPlacementIds": [
        "fullBack"
      ],
      "decoSizeIds": [
        "6",
        "8"
      ]
    },
    "asset": {
      "align": "c",
      "fitMode": "fitBox"
    }
  }
}

const sheetArray = [
  sheet['frontLogo'],
  sheet['sleeveLogo'],
  sheet['backLogo'],
  sheet['teamName'],
  sheet['playerName'],
  sheet['frontNumber'],
  sheet['backNumber'],
]

export default sheet
export { sheetArray }
