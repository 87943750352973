/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "proAero": {
    "name": "Pro Aero",
    "id": "proAero",
    "subsets": {
      "fullButtonJersey": true,
      "regularJersey": true,
      "volleyballJersey": false,
      "replicaJersey": false,
      "pant": false
    }
  },
  "doubleKnit": {
    "name": "Double Knit",
    "id": "doubleKnit",
    "subsets": {
      "fullButtonJersey": true,
      "regularJersey": false,
      "volleyballJersey": false,
      "replicaJersey": false,
      "pant": true
    }
  },
  "stretch": {
    "name": "Stretch",
    "id": "stretch",
    "subsets": {
      "fullButtonJersey": false,
      "regularJersey": true,
      "volleyballJersey": true,
      "replicaJersey": false,
      "pant": false
    }
  },
  "honeycomb": {
    "name": "Honeycomb",
    "id": "honeycomb",
    "subsets": {
      "fullButtonJersey": false,
      "regularJersey": false,
      "volleyballJersey": false,
      "replicaJersey": false,
      "pant": false
    }
  },
  "bunkerGrid": {
    "name": "Bunker Grid",
    "id": "bunkerGrid",
    "subsets": {
      "fullButtonJersey": true,
      "regularJersey": true,
      "volleyballJersey": false,
      "replicaJersey": true,
      "pant": false
    }
  },
  "pinHole": {
    "name": "Pin Hole",
    "id": "pinHole",
    "subsets": {
      "fullButtonJersey": false,
      "regularJersey": true,
      "volleyballJersey": false,
      "replicaJersey": false,
      "pant": false
    }
  }
}

const sheetArray = [
  sheet['proAero'],
  sheet['doubleKnit'],
  sheet['stretch'],
  sheet['honeycomb'],
  sheet['bunkerGrid'],
  sheet['pinHole'],
]

export default sheet
export { sheetArray }
