/* eslint-disable */

// This is generated by a script, see platform/common/importer


const sheet = {
  "solid": {
    "name": "Solid Fill",
    "id": "solid",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 1,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "solidFill",
    "limitations": {
      "colorize": true,
      "fitMode": null,
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "customCamo": {
    "name": "Custom Camo",
    "id": "customCamo",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 3,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "customGraphicTexture",
    "limitations": {
      "colorize": true,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "carbon": {
    "name": "Carbon",
    "id": "carbon",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 1,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "customGraphicTexture",
    "limitations": {
      "colorize": true,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "plate": {
    "name": "Plate",
    "id": "plate",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 1,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "customGraphicTexture",
    "limitations": {
      "colorize": true,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "spade": {
    "name": "Spade",
    "id": "spade",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 1,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "customGraphicTexture",
    "limitations": {
      "colorize": true,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "honey": {
    "name": "Honey",
    "id": "honey",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 1,
      "altColorId": null,
      "color_1": null,
      "color_2": null,
      "color_3": null,
      "color_4": null,
      "color_5": null
    },
    "type": "customGraphicTexture",
    "limitations": {
      "colorize": true,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "hunter": {
    "name": "Hunter",
    "id": "hunter",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "white",
      "color_1": "#8c8c48",
      "color_2": "#31251b",
      "color_3": "#2a4634",
      "color_4": "#231f20",
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "multiCam": {
    "name": "Multi Cam",
    "id": "multiCam",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "white",
      "color_1": "#977763",
      "color_2": "#8f7863",
      "color_3": "#a6886c",
      "color_4": "#bca18c",
      "color_5": "#806256"
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "digitalSand": {
    "name": "Digital Sand",
    "id": "digitalSand",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "black",
      "color_1": "#e7e1d6",
      "color_2": "#aba080",
      "color_3": "#7c7461",
      "color_4": null,
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "digitalSnow": {
    "name": "Digital Snow",
    "id": "digitalSnow",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "black",
      "color_1": "#ebeff2",
      "color_2": "#c5d3d5",
      "color_3": "#b0bac1",
      "color_4": null,
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "digitalDark": {
    "name": "Digital Dark",
    "id": "digitalDark",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "white",
      "color_1": "#7b806c",
      "color_2": "#686253",
      "color_3": "#3e4c4b",
      "color_4": null,
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "digitalTan": {
    "name": "Digital Tan",
    "id": "digitalTan",
    "asset": {
      "isLayoutElement": false,
      "format": "svg",
      "colorCount": 0,
      "altColorId": "white",
      "color_1": "#cfc2aa",
      "color_2": "#9c9786",
      "color_3": "#7c7461",
      "color_4": null,
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  },
  "realFire": {
    "name": "Real Fire",
    "id": "realFire",
    "asset": {
      "isLayoutElement": false,
      "format": "png",
      "colorCount": 0,
      "altColorId": "black",
      "color_1": "#ad2023",
      "color_2": "#ffea34",
      "color_3": "#f26322",
      "color_4": null,
      "color_5": null
    },
    "type": "staticGraphicTexture",
    "limitations": {
      "colorize": false,
      "fitMode": "tile",
      "patternHeight": null,
      "patternHeightScale": null
    }
  }
}

const sheetArray = [
  sheet['solid'],
  sheet['customCamo'],
  sheet['carbon'],
  sheet['plate'],
  sheet['spade'],
  sheet['honey'],
  sheet['hunter'],
  sheet['multiCam'],
  sheet['digitalSand'],
  sheet['digitalSnow'],
  sheet['digitalDark'],
  sheet['digitalTan'],
  sheet['realFire'],
]

export default sheet
export { sheetArray }
